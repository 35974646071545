import React from "react";
import Trans from "../../../../components/Trans";

export default [
   {
      selector: '[data-tut="withdrawalDateSection"]',
      content: () => (
         <div>
            <p>تاریخ برداشت را در اینجا وارد کنید</p>
            <p>
               <span style={{ color: "red" }}>نکته: </span>
               <span>
                  <Trans>withdrawalDateNote</Trans>
               </span>
            </p>
         </div>
      ),
   },
   {
      selector: '[data-tut="inventorySection"]',
      content: () => (
         <div>
            <p>موجودی قابل برداشت شما در این بخش نمایش داده شده</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="withdrawalPrice"]',
      content: () => (
         <div>
            <p>مبلغ برداشت را در این بخش وارد کنید</p>
            <p>با فشاردادن دکمه 'برداشت کل موجودی' میتوانید کل موجودی را در این بخش قرار دهید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="IbanSection"]',
      content: () => (
         <div>
            <p>شماره شبای بانکی که مایلید مبلغ به آن واریز شود را در این بخش انتخاب کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="addBankCard"]',
      content: () => (
         <div>
            <p>
               چنانچه هنوز کارت بانکی ای اضافه نکرده ایید میتوانید با فشار دادن این دکمه مستقیما به صفحه اضافه کردن کارت بانکی هدایت میشوید
            </p>
         </div>
      ),
   },
   {
      selector: '[data-tut="TwoFactorCodeSection"]',
      content: () => (
         <div>
            <p>در این بخش ابتدا دکمه 'درخواست کد پیامکی' را بفشارید سپس کد ارسال شده به همراه خود را در این بخش وارد کنید</p>
         </div>
      ),
   },

   {
      selector: '[data-tut="feeSection"]',
      content: () => (
         <div>
            <p>کارمزد انتقال شبکه بانکی در این بخش نمایش داده میشود</p>
         </div>
      ),
   },
];
