import React, { useState } from "react";

const dic = {
   dashboard: "Armaturenbrett",
   wallet: "Brieftasche",
   markets: "M?rkte",
   quickDealing: "SchnellesGesch?ft",
   systemGuide: "Systemhandbuch",
   today: "heute",
   BackToDashboard: "zurückzumDashboard",
   profile: "Profil",
   security: "Sicherheit",
   accounting: "Buchhaltung",
   report: "Berichte",
   support: "Unterstützung",
   exit: "Ausgang",
   NegotiableCapital: "VerhandelbaresKapital",
   TomanCapital: "Toman-Hauptstadt",
   SatrexDayTradingVolume: "Satrex-Tageshandelsvolumen",
   editPersonalInfo: "Pers?nlicheDatenbearbeiten",
   legal: "legal",
   natural: "natürlich",
   user: "Benutzer",
   level: "Niveau",
   woman: "Frau",
   man: "Mann",
   firstName: "Vorname",
   lastName: "Nachname",
   nationalId: "NationalerKodex",
   birthDate: "Geburtstag",
   email: "Email",
   postCode: "Postleitzahl",
   address: "DieAnschrift",
   telephone: "Telefon",
   phoneNumber: "Handy,Mobiltelefon",
   walletStatus: "Wallet-Status",
   uploadNationalCardImage: "EinnationalesKartenbildhochladen",
   pictureOnNationalCard: "BildaufnationalerKarte",
   sendPersonalPhotoAsPattern: "Einpers?nlichesBildnachMustersenden",
   saveInfo: "Speichern",
   enable: "Aktivieren",
   disable: "Deaktivieren",
   seeSample: "Beispielsehen",
   dragAndDropImageOrClick: "Bildziehenundablegenoderklicken",
   buy: "Kaufen",
   sell: "verkaufen",
   depositAndWithdrawCrypto: "Kryptow?hrungeinzahlenundabheben",
   deposit: "Geldeinzahlen",
   withdrawal: "Rückzug",
   turnover: "Umsatz",
   addCard: "Bankkartehinzufügen",
   addBankAccount: "Bankkontohinzufügen",
   requests: "Anfragen",
   editProfile: "Pers?nlicheDatenbearbeiten",
   editBankInfo: "Bankdatenbearbeiten",
   messages: "Mitteilungen",
   personalInfo: "Pers?nlicheInformationen",
   contactInfo: "Kontakte",
   sendingDocs: "Dokumentesenden",
   tether: "Tether",
   UserLevelUpgrade: "UpgradeaufBenutzerebene",
   YourUserlevel: "IhreBenutzerebene",
   YourTransactionFee: "IhreTransaktionsgebühr",
   DailyWithdrawalCeiling: "Tagesbezugsgrenze",
   DailyCryptoWithdrawalCeiling: "T?glicheAuszahlungsobergrenzefürKryptow?hrungen",
   rial: "Rial",
   NumberOfOpenOrders: "AnzahlderoffenenBestellungen",
   ValueOfTransactionsInTheLast30Days: "WertderTransaktionenindenletzten30Tagen",
   NumberOfOpenBuyOrders: "AnzahloffenerKaufauftr?ge",
   NumberOfOpenSellOrders: "AnzahlderoffenenVerkaufsauftr?ge",
   YourTotalAssets: "IhreBilanzsumme",
   WalletStatus: "Wallet-Status",
   MarketsInTheLast24Hours: "M?rkteindenletzten24Stunden",
   searchCrypto: "Kryptow?hrungsuchen",
   mostGrowth: "dasgr??teWachstum",
   mostFalls: "ammeistenfallen",
   BasedOnMarketRanking: "BasierendaufdemMarktranking",
   BasedOnMarketCap: "BasierendaufMarktkapitalisierung",
   cryptocurrency: "Kryptow?hrung",
   price: "Preis",
   changes: "?nderungen",
   totalSupply: "UmlaufendeVersorgung",
   more: "Mehr",
   inventory: "Inventar",
   AverageBuy: "DurchschnittlicherKauf",
   PriceInTomans: "PreisinToman",
   PriceInTether: "PreisinTether",
   "24Change": "Prozentuale?nderungindenletzten24Stunden",
   market: "Markt",
   systemExit: "Ausloggen",
   sureToGetOut: "SindSiesicher,dassSiesichabmeldenm?chten?",
   cancel: "Abbrechen",
   yes: "Jawohl",
   Deposit: "Anzahlung",
   chooseCrypto: "W?hlenSieeineKryptow?hrung",
   WithdrawalAmount: "Auszahlungsbetrag",
   destinationWalletAddress: "Ziel-Wallet-Adresse",
   TwoFactorCode: "Zwei-Faktor-Identifikationscode",
   requestSMScode: "SMS-Codeanfordern",
   transferFee: "Abl?se",
   SubmitWithdrawalRequest: "Auszahlungsantragstellen",
   depositCryptocurrency: "Kryptow?hrungeinzahlen",
   walletBalance: "Wallet-Guthaben",
   bankCard: "Bankkarte",
   depositAmount: "Einzahlungsbetrag",
   yourDepositCeiling: "IhreEinzahlungsobergrenze",
   TransferBankPortal: "UbergabeandasBankportal",
   withdrawalTime: "Entzugszeit",
   confirm: "Best?tigenSie",
   clear: "Klar",
   withdrawalDateNote: "Notiz;DasAuszahlungsdatumkannnurmorgenundamWerktagsein",
   withdrawalAmount: "Auszahlungsbetrag",
   WithdrawalTotalInventory: "Gesamtbestand",
   DestinationAccountNumber: "Zielkontonummer",
   BankingNetworkTransferFee: "GebührfürBankverbindungen",
   id: "ICHWURDE",
   date: "Datum",
   time: "Zeit",
   type: "Typ",
   currency: "W?hrung",
   amount: "Betragen",
   fee: "Gebühr",
   Withdrawal: "Rückzug",
   transactionDetails: "Transaktionsdetails",
   symbol: "Symbol",
   bankTrackingID: "Bank-Tracking-ID",
   fullName: "VornameundNachname",
   requestDay: "Anfragetag",
   status: "Status",
   description: "Beschreibung",
   bankCardNumber: "Bankkartennummer",
   bankIbanNumber: "Bank-IBAN-Nummer",
   bankName: "BankName",
   awaitingApproval: "WartenaufGenehmigung",
   accepted: "Akzeptiert",
   rejected: "Hatabgelehnt",
   bank: "Bank",
   meliBank: "MeliIran",
   sepahBank: "Sepah",
   toseBank: "ToseSaderat",
   sanatBank: "SanatvaMadan",
   keshavarziBank: "Keshavarzi",
   maskanBank: "Maskana",
   postBank: "PostbankIran",
   toseTavonBank: "ToseTaavon",
   eghtesadBank: "EghtesadNovin",
   parsianbank: "Parsisch",
   pasargardBank: "Pasargad",
   karafarinBank: "Karafarin",
   samanbank: "Saman",
   sinaBank: "Sina",
   sarmayeBank: "Sarmaye",
   tatBank: "Tat",
   shahrBank: "Schah",
   deyBank: "Dey",
   saderatBank: "Saderat",
   melatBank: "Melat",
   tejarat: "Tejarat",
   refahbank: "Refa",
   ansarBank: "Ansar",
   mehrEghtesadBank: "MehrAchtesad",
   zeroLevelUser: "Null-Level-Benutzer",
   oneLevelUser: "EinstufigerBenutzer",
   twoLevelUser: "Zwei-Ebenen-Benutzer",
   authentication: "Authentifizierung",
   bankInfo: "BankInformationen",
   personalImage: "Pers?nlichesFoto",
   adminRejection: "DieindiesemAbschnitteingegebenenInformationenwurdenvomAdministratornichtgenehmigt!BittenachBearbeitungerneuteinreichen",
   adminAcceptation: "DieInformationenindiesemAbschnittwurdenvomAdministratorgenehmigt",
   adminPending: "DieInformationenindiesemAbschnittwurdenandenAdministratorgesendetundwartenaufGenehmigung",
   cancelRequest: "Anfrageabbrechen",
   province: "Provinz",
   city: "Stadt",
   submitInformation: "Informationenübermitteln",
   submitInfoAsk: 'KlickenSieauf"Senden",wennSiesichersind,dassIhreInformationenkorrektsind.',
   save: "speichern",
   saveAndSubmit: "speichernundabsenden",
   varifyEmailCode: "DerBest?tigungscodewurdeanIhreE-Mailgesendet",
   send: "senden",
   validationCode: "Validierungscode",
   code: "Code",
   topic: "Thema",
   sendDate: "Sendedatum",
   changePassword: "?nderdasPasswort",
   enterCurrentPassword: "GibdeinaktuellesPasswortein",
   EnterNewPassword: "GibdeinneuesPasswortein",
   repeatNewPassword: "WiederholenSie,umIhraktuellesPassworteinzugeben",
   activeTwoFactorIdentification: "Zwei-Schritt-Identifikationaktivieren",
   ActivateGoogleAuth: "Google-Authentifikatoraktivieren",
   EnableLoginSMS: "Anmelde-SMSaktivieren",
   LoginLogoutInformation: "An-undAbmeldeinformationen",
   browserId: "Browser-ID",
   Ip: "Ip",
   LoginLogout: "An-undAbmelden",
   ViewFinancialReports: "Finanzberichteanzeigen",
   AmountInRials: "BetraginRial",
   networkFee: "Netzgebühr",
   maxBuy: "H?chstbetragIhresEinkaufs",
   registerBuyorder: "EinenKaufauftragregistrieren",
   registerSellorder: "EinenVerkaufsauftragregistrieren",
   WalletAddress: "Wallet-Adresse",
   ongoingOrders: "LaufendeBestellungen",
   completedOrders: "AbgeschlosseneBestellungen",
   SendMessageToBackup: "NachrichtanSystemsicherungsenden",
   requestSubject: "BetreffderAnfrage",
   message: "Botschaft",
   sendMessage: "Nachrichtsenden",
   EnterPassword: "GebenSieIhrPasswortein",
   forgotPassword: "IchhabemeinPasswortvergessen",
   completeNumbers: "Bittevervollst?ndigenSiedieZahlenmit123",
   LoginToSatrex: "BeiSatrexanmelden",
   RegisterAtSatrex: "BeiSatrexregistrieren",
   "newUser?": "SindSieeinneuerBenutzer?",
   urlCheck: "BitteüberprüfenSie,obSievondieserAdresseauseingegebenhaben",
   mobile: "Handy,Mobiltelefon",
   RepeatPassword: "WiederholedieEingabedeinesPasswortes",
   repeatPassword: "Passwortwiederholen",
   "alreadyRegistered?": "bereitsregistriert?",
   EnterAuthCode: "Auth-Codeeingeben",
   codeSentToMobile: "Best?tigungscodeanHandynummergesendet",
   ResendCodeUp: "Codeerneutsendenbis",
   resend: "erneutsenden",
   continue: "fortsetzen",
   password: "Passwort",
   transactionValue: "DerWertderTransaktion",
   BoardOfCompletedTransaction: "VorstandderabgeschlossenenTransaktion",
   theLowestPrice: "derniedrigstePreis",
   theHighestPrice: "derh?chstePreis",
   BuyAndSellOrders: "Kauf-undVerkaufsauftr?ge",
   Number: "Nummer",
   SendOrder: "Bestellungsenden",
   openOrders: "OffeneBestellungen",
   row: "Reihe",
   currencyName: "W?hrungsname",
   requestedCurrency: "AngeforderteW?hrung",
   OrderType: "Auftragsart",
   totalAmount: "Gesamtsumme",
   TheAmountFilled: "DergefüllteBetrag",
   todayOrders: "HeuteBestellungen",
   cancelOrder: "Bestellungstornieren",
   "sureToCancelOrder?": "SindSiesicher,dassSiedieBestellungstornierenm?chten?",
   marketsOf: "M?rkte",
   update: "aktualisieren",
   depositPageDescriptionLine1:
      "UmdasGuthabenIhrerRial-Geldb?rsezuerh?hen,indemSiedieinIhremProfilundüberdasOnline-ZahlungsportalgenehmigtenBankkartenvonShetab-Netzwerkmitgliedernverwenden.",
   depositPageDescriptionLine2: "BeachtenSiebeimBezahlenfolgendePunkte",
   depositPageDescriptionLine3:
      "NachdemSiedieSeitenadressedesBankportalsüberprüftundsichergestellthaben,dassdieAdressederShaparak-Systemseitenkorrektist,gebenSieIhreBankkartendateneinundt?tigenSieeineEinzahlung.",
   depositPageDescriptionLine4: "StellenSieaufderPortalseitesicher,dassdereingegebeneBetragkorrektist.",
   depositPageDescriptionLine5:
      "BeachtenSiebeiderBestimmungdesEinzahlungsbetrags,dassderMindesttransaktionsbetragaufdemSatrex-MarkthunderttausendTomanbetr?gt.",
   depositWithdrawalCryptoPageLine1:
      "DieAdresseIhrerBrieftaschek?nnenSiedemuntenstehendenFeldentnehmen.VerwendenSiedieangezeigteAdresse,umdigitaleW?hrungenindieseTascheeinzuzahlen.",
   depositWithdrawalCryptoPageLine2: "WennSieIhrWallet-Guthabenabhebenm?chten,registrierenSieIhreAnfragehier.",
   securityPage1:
      "DurchdieAktivierungderZwei-Faktor-AuthentifizierungfürdieAnmeldungmüssenSienebenIhremBenutzernamenundIhremPasswortaucheinensechsstelligenCodeeingeben.UmdiesenCodezugenerieren,verwendenSieeineApp,dieaufIhremTelefoninstalliertwird.DieAktivierungderZwei-Faktor-Authentifizierungerh?htdieSicherheitIhresKontosundeswirdempfohlen,siezuaktivieren.",
   securityPageTableLine1:
      "UmIhrKontoweiterzuschützen,wirdempfohlen,dieanSiegesendetenAnmeldebenachrichtigungs-E-Mailszubeachten.Siek?nnenauchdenVerlauferfolgreicherundfehlgeschlagenerAnmeldungenzuIhremKontoeinsehen.",
   destinationWalletAddressNote:
      "Notiz;StellenSiesicher,dassdievonIhneneingegebeneAdressekorrektist.DiefalscheAdresseführtzumVerlustIhresAuszahlungsbetrags.",
   YourWithdrawableInventory: "IhrentnehmbarerBestand",
   clipboardPermissionsError: "ZugriffaufdieZwischenablagenichtm?glich.BitteumErlaubnisbeantragen",
   copied: "Kopiert",
   showAddress: "Adresseanzeigen",
   updateInventory: "Inventaraktualisieren",
   operations: "Operationen",
   reports: "Berichte",
   services: "Dienstleistungen",
   calculator: "Taschenrechner",
   introduceFriends: "Freundenvorstellen",
   apiDocs: "API-Dokumentation",
   guide: "Handbuch",
   registerGuide: "Registrierungsleitfaden",
   FAQ: "FAQ",
   authGuide: "AuthentifizierungsleitfadenundBenutzerebene",
   priceGuide: "HilfepreiseundProvisionen",
   securityGuide: "Kontosicherheitsleitfaden",
   satrex: "Satrex",
   aboutUs: "überuns",
   contactus: "Kontaktiereuns",
   terms: "Nutzungsbedingungen",
   satrexAddress: "Mashhad-SayadShiraziBoulevard-StasenterGesch?ftskomplex",
   onSocialMedia: "WirsindinsozialenNetzwerken",
   satrexRights: "DiemateriellenundgeistigenRechtedieserSitegeh?renSatorexundjedeVervielf?ltigungdavonwirdstrafrechtlichverfolgt.",
   AboutSatrex: "UberSatrex",
   headOfficeInfo: "KontaktinformationenderZentrale",
   phone: "Telefon",
   openingHours: "?ffnungszeiten",
   openingHoursSatrex: "SamstagbisMittwochvon8:30bis17",
   contactForm: "KontaktFormular",
   firstNameAndLastName: "VornameundNachname",
   contactNumber: "Telefonnummer",
   marketCap: "Marktkapitalisierung",
};

export default props => {
   return dic[props];
};
