import axios from "axios";
import moment from "moment";
import axiosRetry from "axios-retry";
import starkString from "starkstring";
import { createBrowserHistory } from "history";
import { Store } from "../redux/store";
import { setJwtToken, setRefreshTokenTime } from "../redux/actions/LoginActions";
import getEnv from "./helpers/getEnv";

export const refreshTokenUrl = "/Token/Refresh";

const requestHeader = axios.create({
   baseURL: `https://enduserapi.${getEnv()}.ir/api/v1`,
   headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Accept-language": "fa-IR",
      "Access-Control-Allow-Origin": "*",
   },
});

export const authRoutes = route => {
   switch (route) {
      case "/login":
         return true;
      case "/register":
         return true;
      case "/reset-password":
         return true;
      default:
         return false;
   }
};

export default requestHeader;

export const defaultApi = () => {
   delete requestHeader.defaults.headers.common.authorization;
   return requestHeader;
};

export const satrexApi = () => requestHeader;

export const baseUrl = `https://enduserapi.${getEnv()}.ir`;

axiosRetry(axios, {
   retries: 3, // number of retries
   retryDelay: retryCount => retryCount * 2000, // time interval between retries
   retryCondition: error =>
      // console.log('this fn is calling ');
      // if retry condition is not specified, by default idempotent requests are retried
      error.response.status === 401 || !window.navigator.onLine,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
   failedQueue.forEach(prom => {
      if (error) {
         prom.reject(error);
      } else {
         // console.log('token from promise : ', token);
         prom.resolve(token);
      }
   });

   failedQueue = [];
};

requestHeader.interceptors.request.use(config => {
   let token = null;
   if (Store.getState().login.token && Store.getState().login.token.token) {
      token = `Bearer ${Store.getState().login.token.token}`;
   } else if (localStorage.getItem("bearerToken")) {
      token = `Bearer ${localStorage.getItem("bearerToken")}`;
   }
   // console.log('history.location : ', authRoutes(createBrowserHistory().location.pathname));
   // console.log('config : ', config.url);
   config.headers.authorization = authRoutes(createBrowserHistory().location.pathname) || config.url === refreshTokenUrl ? null : token;

   return config;
});

export const handleResetToken = (res, originalRequest) => {
   localStorage.setItem("bearerToken", res.jwtToken.tokenValue);
   Store.dispatch(setJwtToken(res.jwtToken.tokenValue, res.refreshToken.tokenValue));
   Store.dispatch(
      setRefreshTokenTime(
         starkString(
            moment()
               .add(res.jwtToken.expiresInSeconds + 120, "seconds")
               .format("DD/MM/YYYY HH:mm:ss"),
         )
            .englishNumber()
            .toString(),
      ),
   );
   localStorage.setItem(
      "expirationDate",
      starkString(
         moment()
            .add(res.jwtToken.expiresInSeconds + 120, "seconds")
            .format("DD/MM/YYYY HH:mm:ss"),
      )
         .englishNumber()
         .toString(),
   );

   localStorage.setItem("refreshToken", res.refreshToken.tokenValue);
   if (originalRequest) originalRequest.headers.authorization = `Bearer ${res.jwtToken.tokenValue}`;
   processQueue(null, res.jwtToken.tokenValue);
};

export const removeAuth = (error = true) => {
   processQueue(error, null);
   localStorage.removeItem("bearerToken");
   localStorage.removeItem("refreshToken");
   Store.dispatch(setJwtToken(null, null));
   delete axios.defaults.headers.common.authorization;
};

satrexApi().interceptors.response.use(
   response => response,
   error => {
      const originalRequest = error.config;
      // history.replace('no-internet')
      if (!window.navigator.onLine) {
         // Store.dispatch(addRedirection({ fromUrl: '/*', toUrl: '/no-internet' }));
         // console.log('navigating !!!!');
         // return false;
         originalRequest._retry = false;
         setTimeout(() => {
            satrexApi()(originalRequest);
         }, 10000);
      }

      if (error.response !== undefined && error.response.status === 400) {
         // error.response.status = 200;
         // Store.dispatch(addRedirection({ fromUrl: '/*', toUrl: '/login' }));
         // console.log(error.response.data);
      }
      if (
         error.response !== undefined &&
         error.response.status === 401 &&
         !originalRequest._retry &&
         Store.getState().login.token &&
         Store.getState().login.token.token
      ) {
         if (isRefreshing) {
            return new Promise((resolve, reject) => {
               failedQueue.push({ resolve, reject });
            })
               .then(token => {
                  originalRequest.headers.authorization = `Bearer ${token}`;
                  return axios(originalRequest);
               })
               .catch(err => Promise.reject(err));
         }
         isRefreshing = true;
         originalRequest._retry = true;

         return new Promise((resolve, reject) => {
            requestHeader
               .post(
                  refreshTokenUrl,
                  {
                     refreshToken: Store.getState().login.token.refreshToken || localStorage.getItem("refreshToken"),
                  },
                  {
                     headers: {
                        authorization: "",
                     },
                  },
               )
               .then(async res => {
                  if (res.status === 201 || res.status === 200) {
                     localStorage.removeItem("refreshToken");
                     if (!res.data.isSuccess) {
                        // Store.dispatch(addRedirection({ fromUrl: '/*', toUrl: '/login' }));
                        try {
                           delete axios.defaults.headers.common.authorization;
                           localStorage.removeItem("bearerToken");
                           localStorage.removeItem("refreshToken");
                           Store.dispatch(setJwtToken(null, null));
                           await new Promise(reso => {
                              const refreshTokenEvt = new CustomEvent("refreshTokenEvt", {
                                 detail: { resolve: reso, originalRequest },
                              });
                              window.dispatchEvent(refreshTokenEvt);
                           });
                        } catch (e) {
                           reject();
                        }
                     } else {
                        handleResetToken(res.data.data, originalRequest);
                        resolve(requestHeader(originalRequest));
                     }
                  } else {
                     localStorage.removeItem("bearerToken");
                     localStorage.removeItem("refreshToken");
                     requestHeader.defaults.headers.common.authorization = null;
                     await new Promise(reso => {
                        const refreshTokenEvt = new CustomEvent("refreshTokenEvt", {
                           detail: { resolve: reso, originalRequest },
                        });
                        window.dispatchEvent(refreshTokenEvt);
                     });
                  }
               })
               .catch(async err => {
                  localStorage.removeItem("bearerToken");
                  localStorage.removeItem("refreshToken");
                  delete axios.defaults.headers.common.authorization;
                  await new Promise(reso => {
                     const refreshTokenEvt = new CustomEvent("refreshTokenEvt", {
                        detail: { resolve: reso, originalRequest },
                     });
                     window.dispatchEvent(refreshTokenEvt);
                  });
               })
               .then(() => {
                  isRefreshing = false;
               });
         });
      }
      return Promise.reject(error);
   },
);
