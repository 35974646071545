import home from "./Main/tourConfig";
import reports from "./Reports/tourConfig";
import security from "./Security/tourConfig";
import wallet from "./Wallet/tourConfig";
import fastDealing from "./FastDealing/tourConfig";
import markets from "./Markets/tourConfig";
import market from "./Market/tourConfig";
import profile from "./Profile/tourConfig";
import marketDeal from "./Market/MarketDeal/tourConfig";
import accounting from "./Accounting/tourConfig";
import depositMoney from "./Accounting/screens/DepositMoney/tourConfig";
import withdrawalMoney from "./Accounting/screens/WithdrawalMoney/tourConfig";
import depositCrypto from "./Accounting/screens/DepositWithdrawalCrypto/depositTourConfig";
import withdrawalCrypto from "./Accounting/screens/DepositWithdrawalCrypto/withdrawalTourConfig";
import editBankInfo from "./Profile/screens/EditBankInfo/tourConfig";

export default {
   home,
   // setting,
   market,
   depositMoney,
   withdrawalMoney,
   depositCrypto,
   withdrawalCrypto,
   editBankInfo,
   //
   // support,
   accounting,
   // messages,
   // about,
   // policies,
   // guide,
   wallet,
   security,
   markets,
   reports,
   profile,
   "market-deal": marketDeal,
   // 'send-ticket':sendTicket,
   "fast-dealing": fastDealing,
   // 'contact-us':contactUs,
   // 'no-internet':noInternet,
};
