import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { NativeBiometric } from "capacitor-native-biometric";
import { setJwtToken, setRefreshTokenTime } from "../redux/actions/LoginActions";

const sessionUrls = ["/login", "/register", "/reset-password"];

export default ({ children }) => {
   const dispatch = useDispatch();
   const refreshTokenTime = useSelector(state => state.login.refreshTokenTime);
   const token = useSelector(state => state.login.token);
   const { name: routeName } = useParams();
   const history = useHistory();
   const [appReady, setAppReady] = useState(false);
   const checkJwtAuth = async () => {
      dispatch(setRefreshTokenTime(localStorage.getItem("expirationDate")));
      dispatch(setJwtToken(localStorage.getItem("bearerToken"), localStorage.getItem("refreshToken")));
      if (routeName !== "/register" && !localStorage.getItem("bearerToken")) {
         history.replace({
            pathname: "/login",
            state: { prevRoute: history.location.pathname },
         });
      } else if (routeName !== "/register") {
         if (localStorage.getItem("refreshToken")) {
            setAppReady(true);
            return;
         }

         if (
            moment().format("DD/MM/YYYY HH:mm:ss") > refreshTokenTime ||
            moment().format("DD/MM/YYYY HH:mm:ss") > localStorage.getItem("expirationDate")
         ) {
            try {
               const result = await NativeBiometric.isAvailable({ useFallback: true });

               if (result.isAvailable) {
                  setAppReady(true);
                  return;
               }
            } catch (e) {}

            history.replace({
               pathname: "/login",
               state: { prevRoute: history.location.pathname },
            });
         }
      }
   };

   useEffect(() => {
      checkJwtAuth();
   }, []);

   return <>{appReady ? children : ""}</>;
};
