import React from "react";

export default [
   {
      selector: '[data-tut="notifSection"]',
      content: () => (
         <div>
            <p>برای عوض کردن تم میتوانید از این گزینه استفاده کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="menuButton"]',
      content: () => (
         <div>
            <p>با فشار دادن این بخش منو کنار ظاهر میشود</p>
         </div>
      ),
   },
];
