import React from "react";

export default [
   {
      selector: '[data-tut="chooseBankAccount"]',
      content: () => (
         <div>
            <p>برای واریز وجه ابتدا کارت خود را از این بخش انتخاب کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="depositPrice"]',
      content: () => (
         <div>
            <p>مبلغی که قصد واریز آن را دارید بر اساس سقف واریزی خود در این بخش وارد کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="maxDepositSection"]',
      content: () => (
         <div>
            <p>سقف واریزی شما در این بخش نمایش داده میشود</p>
         </div>
      ),
   },
];
