import { NativeBiometric } from "capacitor-native-biometric";
import { transFn } from "../../components/Trans";
import isPhone from "./isPhone";

export default async () => {
   if (isPhone()) {
      const result = await NativeBiometric.isAvailable({ useFallback: true });

      if (!result.isAvailable) return;

      const verified = await NativeBiometric.verifyIdentity({
         reason: "For easy log in",
         title: transFn("login"),
      })
         .then(() => true)
         .catch(() => false);

      if (!verified) return;

      const credentials = await NativeBiometric.getCredentials({
         server: "pwa.satrex.ir",
      });
      return credentials;
   }
};
