export const satrexGreen = "#02b5a0";
export const satrexVeryLightkBlue = "#f9f8fd";
export const satrexLightBlue = "#4C2AF2";
export const satrexDarkBlue = "#00052f";
export const satrexDarkerBlue = "#02072f";
export const satrexWhite = "white";
export const satrexLightGray = "#f7f7f7";
export const satrexSemiGray = "#e5e8f1";
export const satrexGray = "#666666";
export const satrexBlack = "#000000";
export const satrexRed = "#FA4616";
export const satrexCream = "#f7f7f7";
export const satrexDarkBlueDarkMode = "#13063b";
export const satrexDarkerBlueDarkMode = "#02020e";
export const satrexSemiDarkerBlueDarkMode = "#08021c";
export const satrexBorderDarkColor = "#313131";
export const satrexSemiLightGray = "#989898";
export const satrexGray3 = "#8c8b8b";
