import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import CoinReducer from "./CoinReducer";
import MarketReducer from "./MarketReducer";
import LayoutReducer from "./LayoutReducer";
import UserReducer from "./UserReducer";

const RootReducer = combineReducers({
   login: LoginReducer,
   coin: CoinReducer,
   market: MarketReducer,
   layout: LayoutReducer,
   user: UserReducer,
});

const appReducer = (state, action) => {
   if (action.type === "EXITING") {
      state = undefined;
   }

   return RootReducer(state, action);
};

export default appReducer;
