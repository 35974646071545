import React from "react";
import sliderTourConfig from "../../components/slider/tourConfig";
import InnerTabTourConfig from "../../components/InnerTabTourConfig";

export default [
   ...sliderTourConfig,
   ...InnerTabTourConfig,
   {
      selector: '[data-tut="ongoingOrdersList"]',
      content: () => (
         <div>
            <p>لیست سفارشات باز را در این قسمت میتوانید مشاهده کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="completedOrdersList"]',
      content: () => (
         <div>
            <p>لیست سفارشات انجام شده را در این قسمت میتوانید مشاهده کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="FastOrdersList"]',
      content: () => (
         <div>
            <p>لیست سفارشات سریع را در این قسمت میتوانید مشاهده کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="FastDealsList"]',
      content: () => (
         <div>
            <p>لیست معاملات سریع انجام شده را در این قسمت میتوانید مشاهده کنید</p>
         </div>
      ),
   },
];
