import React from "react";

export default [
   {
      selector: '[data-tut="chooseCryptoSection"]',
      content: () => (
         <div>
            <p>برای واریز رمز ارز ابتدا کوین مورد نظر را از این قسمت انتخاب کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="chooseNetwork"]',
      content: () => (
         <div>
            <p>بعد از انتخاب کوین مورد نظر میتوانید شبکه مربوطه را در این قسمت انتخاب کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="WithdrawalAmountSection"]',
      content: () => (
         <div>
            <p>مقدار برداشت را در این بخش وارد کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="destinationWalletAddressSection"]',
      content: () => (
         <div>
            <p>آدرس کیف پول مقصد را در این بخش وارد کنید</p>
            <p>
               <span style={{ color: "red" }}>نکته: </span>
               <span>به هنگام وارد کردن آدرس به نوع کوین و شبکه دقت داشته باشید</span>
            </p>
         </div>
      ),
   },
   {
      selector: '[data-tut="TwoFactorCodeSection"]',
      content: () => (
         <div>
            <p>در این بخش ابتدا دکمه 'درخواست کد پیامکی' را بفشارید سپس کد ارسال شده به همراه خود را در این بخش وارد کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="feeSection"]',
      content: () => (
         <div>
            <p>کارمزد انتقال در این بخش نمایش داده میشود</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="qrCodeSection"]',
      content: () => (
         <div>
            <p>QRCode مربوط به آدرس بلاک چین در این قسمت نمایش داده میشود</p>
         </div>
      ),
   },
];
