import React from "react";

export default [
   {
      selector: '[data-tut="sliderSection"]',
      content: () => (
         <div>
            <p>با کلیک بر روی هر کدام از کوین های موجود در این قسمت میتوانید مستقیما به صفحه معامله بروید</p>
         </div>
      ),
   },
];
