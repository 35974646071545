import { IonApp, useIonToast } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { cssTransition, ToastContainer } from "react-toastify";
import "./styles/global.scss";
import "./styles/chart.scss";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./fonts/icons/font/flaticon.scss";
import "./theme/variables.css";
import React, { useEffect, Suspense, useState } from "react";
import { Route, useParams, Redirect, useHistory } from "react-router";
import "./styles/DarkMode.scss";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useDispatch, useSelector } from "react-redux";
import AskLanguage from "./components/AskLanguage";
import PolicyModal from "./components/PolicyModal";
import NoInternet from "./screens/NoInternet";
import { AppProvider } from "./contexts/AppContext";
import Auth from "./components/Auth";
import { LayoutState } from "./utils/interfaces";
import "animate.css";
import TourContainer from "./components/TourContainer";
import setTheme from "./utils/helpers/setTheme";
import withClearCache from "./ClearCache";
import Loader from "./components/Loader";
import ShowInstallPopUp from "./components/ShowInstallPopUp";
import isPhone from "./utils/helpers/isPhone";
import { loginWithPhoneAndPassword } from "./utils/apis";
import history from "./history";
import { handleResetToken, removeAuth } from "./utils/ApiConfig";
import performBiometricVerification from "./utils/helpers/performBiometricVerification";
import PhonePlugins from "./PhonePlugins";
import { NativeBiometric } from "capacitor-native-biometric";

const Session = React.lazy(() => import("./screens/Session"));
const Tabs = React.lazy(() => import("./components/Tabs"));
const Menu = React.lazy(() => import("./components/Menu"));
const IntroSlides = React.lazy(() => import("./components/Slides"));

const MasterLayout = () => {
   const pathParam = useParams<{ name: string; referralCode: string }>();

   switch (pathParam.name) {
      case "login":
         return <Session routeName={pathParam.name} referralCode={undefined} />;
      case "register":
         return <Session routeName={pathParam.name} referralCode={pathParam.referralCode} />;
      case "reset-password":
         return <Session routeName={pathParam.name} referralCode={undefined} />;
      default:
         return (
            <>
               <Auth>
                  <Tabs />
               </Auth>
            </>
         );
   }
};

const swirl = cssTransition({
   enter: "swirl-in-fwd",
   exit: "swirl-out-bck",
});

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         refetchOnWindowFocus: false,
         cacheTime: 1000 * 60,
      },
   },
});

const MainApp: React.FC = () => {
   const language = useSelector((state: LayoutState) => state.layout.language);
   const redirections = useSelector((state: LayoutState) => state.layout.redirectUrls);
   const theme = useSelector((state: LayoutState) => state.layout.theme);
   const [policyModalOpen, setPolicyModalOpen] = useState(false);
   const dispatch = useDispatch();

   const isLtr = language === "EN";

   useEffect(() => {
      setTheme(theme);
      const isAppInstalled = localStorage.getItem("appInstalled");
      if (!isAppInstalled && isPhone()) {
         setPolicyModalOpen(true);
      }
      localStorage.setItem("appInstalled", "true");
      window.addEventListener("refreshTokenEvt", async (evt: any) => {
         if (isPhone()) {
            const isAvaiable = await NativeBiometric.isAvailable({ useFallback: true });
            let res;

            if (!isAvaiable) {
               const credentialsStr = localStorage.getItem("credentials");
               if (credentialsStr) {
                  const creds: any = JSON.parse(credentialsStr);
                  res = await loginWithPhoneAndPassword(creds.mobile, creds.password);
               }
            } else {
               const credentials = await performBiometricVerification();
               if (credentials) {
                  res = await loginWithPhoneAndPassword(credentials.username, credentials.password);
               } else {
               }
            }
            if (res) {
               if (!res?.otpNeeded) {
                  await handleResetToken(res, evt.detail.originalRequest);
                  evt.detail.resolve();
                  return;
               }
            }
         }
         removeAuth();
         history.push("/login");
         evt.detail.resolve();
      });
   }, []);

   return (
      <QueryClientProvider client={queryClient}>
         <PolicyModal policyModalOpen={policyModalOpen} setPolicyModalOpen={setPolicyModalOpen} />
         <AppProvider>
            <TourContainer>
               <IonApp className={`${isLtr ? "ltrApp" : "rtlApp"} ${theme ? "dark" : ""}`}>
                  <ShowInstallPopUp />
                  {isPhone() && <PhonePlugins />}
                  <Suspense
                     fallback={
                        <div
                           style={{
                              width: "100%",
                              height: "calc(100vh - 50px)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                           }}
                        >
                           <Loader />
                        </div>
                     }
                  >
                     <IonReactRouter history={history}>
                        {redirections.map((item: any, index: any) => (
                           <Redirect key={item.fromUrl} from={item.fromUrl} to={item.toUrl} />
                        ))}
                        <Route path="/no-internet" exact>
                           <NoInternet />
                        </Route>
                        <Route path="/" exact>
                           <Redirect to="/home" />
                        </Route>
                        <Route path="/market/:id" exact>
                           <MasterLayout />
                        </Route>
                        <Route path="/market-deal/:id" exact>
                           <MasterLayout />
                        </Route>
                        <Route path="/wallet/:coinname" exact>
                           <MasterLayout />
                        </Route>
                        <Route path="/intro" exact>
                           <IntroSlides />
                        </Route>
                        <Route path="/:name" exact>
                           <MasterLayout />
                        </Route>
                        <Route path="/:name/:referralCode" exact>
                           <MasterLayout />
                        </Route>
                     </IonReactRouter>
                  </Suspense>
               </IonApp>
            </TourContainer>

            <ReactQueryDevtools initialIsOpen />

            <ToastContainer
               position="top-center"
               autoClose={3000}
               hideProgressBar
               newestOnTop={false}
               closeOnClick
               rtl={!isLtr}
               pauseOnFocusLoss
               style={{ height: 50 }}
               draggable
               pauseOnHover
               transition={swirl}
            />
         </AppProvider>
      </QueryClientProvider>
   );
};

const ClearCacheComponent = withClearCache(MainApp);

function App() {
   return <ClearCacheComponent />;
}
export default App;
