import { IonButton, IonContent, IonModal } from "@ionic/react";
import React from "react";
import { satrexGreen } from "../styles/colors";
import Trans from "./Trans";

interface IPolicyModalProps {
   policyModalOpen: boolean;
   setPolicyModalOpen: (status: boolean) => any;
}

const PolicyModal: React.FC<IPolicyModalProps> = ({ setPolicyModalOpen, policyModalOpen }) => {
   const handleAcceptPolicy = async () => {
      setPolicyModalOpen(false);
   };

   return (
      <IonModal isOpen={policyModalOpen}>
         <IonContent>
            <div className="flex space-between registerPoliciesModalBtns">
               <IonButton strong onClick={handleAcceptPolicy}>
                  <Trans>confirm</Trans>
               </IonButton>
            </div>
            <div className="registerPoliciesModal">
               <>
                  <h4 style={{ color: satrexGreen }}>
                     <Trans>The full text of Satrex's privacy policy</Trans>
                  </h4>
                  <h6 style={{ color: satrexGreen }}>
                     <Trans>How does Satrex protect your information?</Trans>
                  </h6>

                  <Trans>firstLinePolicy</Trans>
                  <h6 style={{ color: satrexGreen }}>
                     <Trans>What information is received from you?</Trans>
                  </h6>
                  <Trans>secondLinePolicy</Trans>
                  <h6 style={{ color: satrexGreen }}>
                     <Trans>How is your information protected?</Trans>
                  </h6>
                  <Trans>thirdLinePolicy</Trans>
                  <h6 style={{ color: satrexGreen }}>
                     <Trans>What will your information be used for?</Trans>
                  </h6>
                  <Trans>forthLinePolicy</Trans>
                  <h6 style={{ color: satrexGreen }}>
                     <Trans>Who has access to your information?</Trans>
                  </h6>
                  <Trans>fifthLinePolicy</Trans>
               </>
            </div>
         </IonContent>
      </IonModal>
   );
};

export default PolicyModal;
