import React, { useState } from "react";

const dic = {
   dashboard: "لوحة القيادة",
   wallet: "محفظة جيب",
   markets: "الأسواق",
   quickDealing: "صفقة سريعة",
   systemGuide: "دليل النظام",
   today: "اليوم",
   BackToDashboard: "العودة إلى لوحة القيادة",
   profile: "الملف الشخصي",
   security: "الأمان",
   accounting: "محاسبة",
   report: "التقارير",
   support: "الدعم",
   exit: "خروج",
   NegotiableCapital: "رأس مال قابل للتداول",
   TomanCapital: "يأخذون رأس المال",
   SatrexDayTradingVolume: "حجم تداول يوم سوتركس",
   editPersonalInfo: "تحرير المعلومات الشخصية\n",
   legal: "الاعتباري",
   natural: "الطبيعي",
   user: "المستعمل",
   level: "مستوى",
   woman: "أنثى",
   man: "رجل",
   firstName: "الاسم الأول",
   lastName: "الكنية",
   nationalId: "رمز دولي",
   birthDate: "تاريخ الولادة",
   email: "البريد الإلكتروني",
   postCode: "رمز بريدي",
   address: "عنوان",
   telephone: "هاتف",
   phoneNumber: "التليفون المحمول",
   walletStatus: "حالة المحفظة",
   uploadNationalCardImage: "قم بتحميل صورة البطاقة الوطنية",
   pictureOnNationalCard: "صورة على البطاقة الوطنية",
   sendPersonalPhotoAsPattern: "إرسال صورة شخصية حسب العينة",
   saveInfo: "يحفظ",
   enable: "ممكن",
   disable: "إبطال",
   seeSample: "انظر عينة",
   dragAndDropImageOrClick: "قم بسحب وإسقاط الصورة أو النقر",
   buy: "شراء",
   sell: "يبيع",
   depositAndWithdrawCrypto: "كود الإيداع والسحب العملة",
   deposit: "إيداع الأموال",
   withdrawal: "انسحاب",
   turnover: "دوران",
   addCard: "أضف بطاقة مصرفية",
   addBankAccount: "أضف حسابًا مصرفيًا",
   requests: "الطلبات",
   editProfile: "تحرير المعلومات الشخصية",
   editBankInfo: "تحرير المعلومات المصرفية",
   messages: "رسائل",
   personalInfo: "معلومات شخصية",
   contactInfo: "جهات الاتصال",
   sendingDocs: "أرسل المستندات",
   tether: "تتر",
   UserLevelUpgrade: "ترقية مستوى المستخدم",
   YourUserlevel: "مستوى المستخدم الخاص بك",
   YourTransactionFee: "رسوم معاملتك",
   DailyWithdrawalCeiling: "سقف السحب اليومي",
   DailyCryptoWithdrawalCeiling: "سقف السحب اليومي للعملات المشفرة",
   rial: "ریال",
   NumberOfOpenOrders: "عدد الأوامر المفتوحة",
   ValueOfTransactionsInTheLast30Days: "قيمة المعاملات في آخر 30 يومًا",
   NumberOfOpenBuyOrders: "عدد أوامر الشراء المفتوحة",
   NumberOfOpenSellOrders: "عدد أوامر البيع المفتوحة",
   YourTotalAssets: "إجمالي الأصول الخاصة بك",
   WalletStatus: "حالة المحفظة",
   MarketsInTheLast24Hours: "الأسواق في آخر 24 ساعة",
   searchCrypto: "ابحث عن العملات المشفرة",
   mostGrowth: "الأكثر نموًا",
   mostFalls: "معظم الخريف",
   BasedOnMarketRanking: "بناءً على ترتيب السوق",
   BasedOnMarketCap: "على أساس حجم التداول",
   cryptocurrency: "عملة مشفرة",
   price: "ثمن",
   changes: "التغييرات",
   totalSupply: "العرض المتداول",
   marketCap: "حجم التداول",
   more: "أكثر",
   inventory: "جرد",
   AverageBuy: "متوسط الشراء",
   PriceInTomans: "السعر في تومان",
   PriceInTether: "السعر في تتر",
   "24Change": "تغيير النسبة المئوية في آخر 24 ساعة",
   market: "سوق",
   systemExit: "تسجيل خروج",
   sureToGetOut: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
   cancel: "يلغي",
   yes: "نعم",
   Deposit: "إيداع",
   chooseCrypto: "اختر عملة معماة",
   WithdrawalAmount: "كمية السحب",
   destinationWalletAddress: "عنوان المحفظة الوجهة",
   TwoFactorCode: "رمز التعريف الثنائي",
   requestSMScode: "طلب رمز SMS",
   transferFee: "رسوم التحويل",
   SubmitWithdrawalRequest: "تقديم طلب سحب",
   depositCryptocurrency: "إيداع العملات المشفرة",
   walletBalance: "رصيد المحفظة",
   bankCard: "بطاقة مصرفية",
   depositAmount: "قيمة الايداع",
   yourDepositCeiling: "سقف الإيداع الخاص بك",
   TransferBankPortal: "التحويل إلى البوابة المصرفية",
   withdrawalTime: "وقت الانسحاب",
   confirm: "يتأكد",
   clear: "صافي",
   withdrawalDateNote: "ملاحظة: يمكن أن يكون تاريخ السحب اعتبارًا من يوم غد وفي يوم العمل فقط",
   withdrawalAmount: "كمية السحب",
   WithdrawalTotalInventory: "إجمالي المخزون",
   DestinationAccountNumber: "رقم حساب الوجهة",
   BankingNetworkTransferFee: "رسوم التحويل عبر الشبكة المصرفية",
   id: "بطاقة تعريف",
   date: "تاريخ",
   time: "وقت",
   type: "اكتب",
   currency: "عملة",
   amount: "مقدار",
   fee: "مصاريف",
   Withdrawal: "انسحاب",
   transactionDetails: "تفاصيل الصفقه",
   symbol: "رمز",
   bankTrackingID: "معرف تتبع البنك",
   fullName: "الاسم الأول واسم العائلة",
   requestDay: "يوم الطلب",
   status: "حالة",
   description: "وصف",
   bankCardNumber: "رقم البطاقة المصرفية",
   bankIbanNumber: "رقم الحساب المصرفي",
   bankName: "اسم البنك",
   awaitingApproval: "انتظر القبول",
   accepted: "وافقت",
   rejected: "مرفوض",
   bank: "مصرف",
   meliBank: " ملی ایران",
   sepahBank: " سپه",
   toseBank: " توسعه صادرات",
   sanatBank: " صنعت و معدن",
   keshavarziBank: " کشاورزی",
   maskanBank: " مسکن",
   postBank: "پست بانک ایران",
   toseTavonBank: " توسعه تعاون",
   eghtesadBank: " اقتصاد نوین",
   parsianbank: " پارسیان",
   pasargardBank: " پاسارگاد",
   karafarinBank: " کارآفرین",
   samanbank: " سامان",
   sinaBank: " سینا",
   sarmayeBank: " سرمایه",
   tatBank: " تات",
   shahrBank: " شهر",
   deyBank: " دی",
   saderatBank: "  صادرات",
   melatBank: " ملت",
   tejarat: " تجارت",
   refahbank: " رفاه",
   ansarBank: " انصار",
   mehrEghtesadBank: " مهر اقتصاد",
   zeroLevelUser: "مستخدم مستوى الصفر",
   oneLevelUser: "مستخدم من مستوى واحد",
   twoLevelUser: "مستخدمين من مستويين",
   authentication: "المصادقة",
   bankInfo: "المعلومات المصرفية",
   personalImage: "صورة شخصية",
   adminRejection: "المعلومات التي تم إدخالها في هذا القسم لم تتم الموافقة عليها من قبل الإدارة! الرجاء إعادة التقديم بعد التحرير",
   adminAcceptation: "تمت الموافقة على المعلومات الواردة في هذا القسم من قبل المسؤول",
   adminPending: "تم إرسال المعلومات الواردة في هذا القسم إلى المسؤول وهي في انتظار الموافقة",
   cancelRequest: "إلغاء الطلب",
   province: "المحافظة",
   city: "مدينة",
   submitInformation: "إرسال المعلومات",
   submitInfoAsk: 'انقر فوق "إرسال" إذا كنت متأكدًا من صحة معلوماتك',
   save: "حفظ",
   saveAndSubmit: "احفظها وأرسلها",
   varifyEmailCode: "تم إرسال رمز التحقق إلى بريدك الإلكتروني",
   send: "يرسل",
   validationCode: "رمز التأكيد",
   code: "الشفرة",
   topic: "عنوان",
   sendDate: "تاريخ الإرسال",
   changePassword: "قم بتغيير كلمة المرور",
   enterCurrentPassword: "أدخل كلمة المرور الحالية",
   EnterNewPassword: "أدخل كلمة المرور الجديدة",
   repeatNewPassword: "كرر لإدخال كلمة مرورك الحالية",
   activeTwoFactorIdentification: "قم بتنشيط التحديد المكون من خطوتين",
   ActivateGoogleAuth: "قم بتنشيط Google Authenticator",
   EnableLoginSMS: "تمكين تسجيل الدخول SMS",
   LoginLogoutInformation: "معلومات تسجيل الدخول والخروج",
   browserId: "معرف المتصفح",
   Ip: "Ip",
   LoginLogout: "تسجيل الدخول والخروج",
   ViewFinancialReports: "عرض التقارير المالية",
   AmountInRials: "المبلغ بالريال",
   networkFee: "رسوم الشبكة",
   maxBuy: "الحد الأقصى لمبلغ الشراء الخاص بك",
   registerBuyorder: "قم بتسجيل طلب شراء",
   registerSellorder: "قم بتسجيل أمر مبيعات",
   WalletAddress: "عنوان المحفظة",
   ongoingOrders: "أوامر جارية",
   completedOrders: "الطلبات المنجزة",
   SendMessageToBackup: "إرسال رسالة إلى نسخة احتياطية للنظام",
   requestSubject: "موضوع الطلب",
   message: "رسالة",
   sendMessage: "إرسال رسالة",
   EnterPassword: "ادخل رقمك السري",
   forgotPassword: "لقد نسيت كلمة المرور",
   completeNumbers: "يرجى إكمال الأرقام كـ 123",
   LoginToSatrex: "تسجيل الدخول إلى ساترکس",
   RegisterAtSatrex: "سجل في ساترکس",
   "newUser?": "هل أنت مستخدم جديد؟",
   urlCheck: "يرجى التحقق من أنك أدخلت من هذا العنوان",
   mobile: "التليفون المحمول",
   RepeatPassword: "إعادة إدخال كلمة المرور الخاصة بك",
   repeatPassword: "كرر كلمة المرور",
   "alreadyRegistered?": "مسجل بالفعل؟",
   EnterAuthCode: "ادخل رمز التصديق",
   codeSentToMobile: "تم إرسال رمز التأكيد إلى رقم الجوال",
   ResendCodeUp: "إعادة إرسال الرمز حتى",
   resend: "إعادة إرسال",
   continue: "استمر",
   password: "كلمه السر",
   transactionValue: "قيمة الصفقة",
   BoardOfCompletedTransaction: "مجلس إدارة الصفقة المنجزة",
   theLowestPrice: "بأقل سعر",
   theHighestPrice: "اغلى سعر",
   BuyAndSellOrders: "أوامر البيع والشراء",
   Number: "عدد",
   SendOrder: "ارسال الطلب",
   openOrders: "أوامر مفتوحة",
   row: "صف",
   currencyName: "اسم العملة",
   requestedCurrency: "العملة المطلوبة",
   OrderType: "نوع الطلب",
   totalAmount: "المبلغ الإجمالي",
   TheAmountFilled: "المبلغ معبأ",
   todayOrders: "أوامر اليوم",
   cancelOrder: "الغاء الطلب",
   "sureToCancelOrder?": "هل أنت متأكد أنك تريد إلغاء الطلب؟",
   marketsOf: "أسواق",
   update: "تحديث",
   depositPageDescriptionLine1:
      "لزيادة رصيد محفظتك الريال باستخدام البطاقات المصرفية لأعضاء شبكة شتاب المعتمدين في ملفك الشخصي ومن خلال بوابة الدفع عبر الإنترنت.",
   depositPageDescriptionLine2: "انتبه إلى النقاط التالية عند الدفع",
   depositPageDescriptionLine3:
      "بعد التحقق من عنوان صفحة البوابة المصرفية والتأكد من صحة عنوان مواقع نظام شاپرک ، أدخل تفاصيل بطاقتك المصرفية وقم بالإيداع.",
   depositPageDescriptionLine4: "في صفحة المدخل ، تأكد من صحة المبلغ الذي تم إدخاله.",
   depositPageDescriptionLine5: "عند تحديد مبلغ الإيداع ، انتبه إلى حقيقة أن الحد الأدنى لمبلغ المعاملة في سوق Satrex هو مائة ألف تومان.",
   depositWithdrawalCryptoPageLine1:
      "يمكن رؤية عنوان محفظتك في المربع أدناه. استخدم العنوان المعروض لإيداع العملات الرقمية في هذه الحقيبة.",
   depositWithdrawalCryptoPageLine2: "إذا كنت ترغب في سحب رصيد محفظتك ، فقم بتسجيل طلبك هنا.",
   securityPage1:
      "من خلال تنشيط المصادقة ذات العاملين لتسجيل الدخول ، بالإضافة إلى اسم المستخدم وكلمة المرور ، ستحتاج أيضًا إلى إدخال رمز مكون من ستة أرقام. لإنشاء هذا الرمز ، يمكنك استخدام تطبيق سيتم تثبيته على هاتفك. يؤدي تمكين المصادقة الثنائية إلى زيادة أمان حسابك ، ويوصى بتمكينه.",
   securityPageTableLine1:
      "من أجل حماية حسابك بشكل أكبر ، يوصى بالاهتمام برسائل البريد الإلكتروني الخاصة بإشعارات تسجيل الدخول التي يتم إرسالها إليك. يمكنك أيضًا عرض محفوظات تسجيلات الدخول الناجحة وغير الناجحة إلى حسابك.",
   destinationWalletAddressNote: "ملاحظة: تأكد من صحة العنوان الذي أدخلته ، سيؤدي العنوان الخاطئ إلى فقدان مبلغ السحب.",
   YourWithdrawableInventory: "مخزونك القابل للسحب",
   clipboardPermissionsError: "غير قادر على الوصول إلى الحافظة يرجى تقديم طلب للحصول على إذن",
   copied: "نسخ",
   showAddress: "إظهار العنوان",
   updateInventory: "تحديث المخزون",
   operations: "عمليات",
   reports: "التقارير",
   services: "خدمات",
   calculator: "آلة حاسبة",
   introduceFriends: "أعرض للأصدقاء",
   apiDocs: "وثائق API",
   guide: "يرشد",
   registerGuide: "دليل التسجيل",
   FAQ: "أسئلة مكررة",
   authGuide: "دليل المصادقة ومستوى المستخدم",
   priceGuide: "مساعدة الأسعار والعمولات",
   securityGuide: "دليل أمان الحساب",
   satrex: "ساترکس",
   aboutUs: "معلومات عنا",
   contactus: "اتصل بنا",
   terms: "شزوط واشتراطات الاستخدام",
   satrexAddress: "مشهد - صياد شيرازي بوليفارد - مجمع ستاسنتر التجاري",
   onSocialMedia: "نحن على الشبكات الاجتماعية",
   satrexRights: "تعود الحقوق المادية والفكرية لهذا الموقع إلى Satorex وسيتم مقاضاة أي نسخ له.",
   AboutSatrex: "حول ساتركس",
   headOfficeInfo: "معلومات الاتصال بالمكتب الرئيسي",
   phone: "هاتف",
   openingHours: "ساعات العمل",
   openingHoursSatrex: "من السبت إلى الأربعاء من الساعة 8:30 إلى الساعة 17",
   contactForm: "نموذج الاتصال",
   firstNameAndLastName: "الاسم الأول واسم العائلة",
   contactNumber: "رقم الهاتف",
   toman: "تومان",
};

export default props => {
   return dic[props];
};
