import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setLanguage } from "../redux/actions/LayoutActions";
import SatrexDialog from "./SatrexDialog";
import Trans from "./Trans";
import { satrexGreen } from "../styles/colors";

interface IAskLanguageProps {}

const AskLanguage: React.FC<IAskLanguageProps> = () => {
   const [showAlert1, setShowAlert1] = useState<boolean>(true);
   const dispatch = useDispatch();

   const handleChangingLanguage = () => {
      dispatch(setLanguage("FA"));
      setShowAlert1(false);
   };

   return (
      <>
         <SatrexDialog
            open={showAlert1}
            handleClose={() => setShowAlert1(false)}
            content={
               <>
                  <h6 style={{ textAlign: "center", lineHeight: 2 }} dir="ltr">
                     <>The default language of this application is Farsi Do you want to change the language or continue with English?</>
                  </h6>
                  <h6 style={{ textAlign: "center", lineHeight: 2 }}>
                     <>زبان پیش فرض این اپلیکیشن فارسی است میخواهید زبان را تغییر دهید یا با انگلیسی ادامه میدهید؟</>
                  </h6>
                  <div
                     style={{
                        width: "100%",
                        marginTop: 10,
                        display: "flex",
                        justifyContent: "center",
                     }}
                  >
                     <Button style={{ color: "gray" }} onClick={handleChangingLanguage}>
                        <Trans>Change to Farsi</Trans>
                     </Button>
                     &nbsp;&nbsp;&nbsp;&nbsp;
                     <Button
                        onClick={() => setShowAlert1(false)}
                        style={{ backgroundColor: satrexGreen }}
                        variant="contained"
                        className="installPwa"
                     >
                        <Trans>continue</Trans>
                     </Button>
                  </div>
               </>
            }
         />
      </>
   );
};

export default AskLanguage;
