const dic = {
   dashboard: "Dashboard",
   wallet: "wallet",
   markets: "Markets",
   quickDealing: "Quick deal",
   systemGuide: "System Guide",
   today: "today",
   BackToDashboard: "back to Dashboard",
   profile: "Profile",
   security: "Security",
   accounting: "Accounting",
   report: "Reports",
   support: "Support",
   exit: "Exit",
   NegotiableCapital: "Negotiable capital",
   TomanCapital: "Toman capital",
   SatrexDayTradingVolume: "Satrex day trading volume",
   editPersonalInfo: "Edit Personal Info",
   legal: "legal",
   natural: "natural",
   user: "",
   level: "Level",
   woman: "woman",
   man: "man",
   firstName: "First name",
   lastName: "Last name",
   nationalId: "National code",
   birthDate: "Birth date",
   email: "Email",
   postCode: "Postal code",
   address: "Address",
   telephone: "phone",
   phoneNumber: "Mobile",
   walletStatus: "Wallet status",
   uploadNationalCardImage: "Upload a national card image",
   pictureOnNationalCard: "Picture on national card",
   sendPersonalPhotoAsPattern: "Send a personal image according to the sample",
   saveInfo: "Save",
   enable: "Enable",
   disable: "Disable",
   seeSample: "See sample",
   dragAndDropImageOrClick: "Drag and drop image or click",
   buy: "buy",
   sell: "sell",
   depositAndWithdrawCrypto: "Deposit and withdraw Cryptocurrency",
   deposit: "Deposit money",
   withdrawal: "Withdrawal",
   turnover: "Turnover",
   addCard: "Add a bank card",
   addBankAccount: "Add a bank account",
   requests: "Requests",
   editProfile: "Edit personal info",
   editBankInfo: "Edit banking info",
   messages: "Messages",
   personalInfo: "Personal Information",
   contactInfo: "Contacts",
   sendingDocs: "Send documents",
   tether: "Tether",
   UserLevelUpgrade: "User level upgrade",
   YourUserlevel: "Your user level",
   YourTransactionFee: "Your transaction fee",
   DailyWithdrawalCeiling: "Daily withdrawal ceiling",
   DailyCryptoWithdrawalCeiling: "Daily Cryptocurrency withdrawal ceiling",
   rial: "Rial",
   NumberOfOpenOrders: "Number of open orders",
   ValueOfTransactionsInTheLast30Days: "Value of transactions in the last 30 days",
   NumberOfOpenBuyOrders: "Number of open buy orders",
   NumberOfOpenSellOrders: "Number of open sell orders",
   YourTotalAssets: "Your total assets",
   WalletStatus: "Wallet status",
   MarketsInTheLast24Hours: "Markets in the last 24 hours",
   searchCrypto: "Search Cryptocurrency",
   mostGrowth: "the most growth",
   mostFalls: "the most fall",
   BasedOnMarketRanking: "Based on market ranking",
   BasedOnMarketCap: "Based on market cap",
   cryptocurrency: "Cryptocurrency",
   price: "Price",
   changes: "Changes",
   totalSupply: "Circulating supply",
   marketCap: "market cap",
   more: "More",
   inventory: "Inventory",
   AverageBuy: "Average purchase",
   PriceInTomans: "Price in Toman",
   PriceInTether: "Price in Tether",
   "24Change": "Percentage change in last 24 hours",
   market: "Market",
   systemExit: "Log-out",
   sureToGetOut: "Are you sure you want to log out?",
   cancel: "Cancel",
   yes: "Yes",
   Deposit: "Deposit",
   chooseCrypto: "Choose a Cryptocurrency",
   WithdrawalAmount: "Withdrawal amount",
   destinationWalletAddress: "Destination wallet address",
   TwoFactorCode: "Two-factor identification code",
   requestSMScode: "Request SMS code",
   transferFee: "Transfer fee",
   SubmitWithdrawalRequest: "Submit a withdrawal request",
   depositCryptocurrency: "Deposit Cryptocurrency",
   walletBalance: "Wallet balance",
   bankCard: "Bank card",
   depositAmount: "Deposit amount",
   yourDepositCeiling: "Your deposit ceiling",
   TransferBankPortal: "Transfer to the banking portal",
   withdrawalTime: "Withdrawal time",
   confirm: "Confirm",
   clear: "Clear",
   withdrawalDateNote: "Note: Withdrawal date can only be from tomorrow and on business day",
   withdrawalAmount: "Withdrawal amount",
   WithdrawalTotalInventory: "total inventory",
   DestinationAccountNumber: "Destination account number",
   BankingNetworkTransferFee: "Banking network transfer fee",
   id: "ID",
   date: "Date",
   time: "Time",
   type: "Type",
   currency: "Currency",
   amount: "Amount",
   fee: "Fee",
   Withdrawal: "Withdrawal",
   transactionDetails: "Transaction details",
   symbol: "Symbol",
   bankTrackingID: "Bank tracking ID",
   fullName: "first name and last name",
   requestDay: "Request day",
   status: "Status",
   description: "Description",
   bankCardNumber: "Bank card number",
   bankIbanNumber: "Bank IBAN number",
   bankName: "Bank name",
   awaitingApproval: "Awaiting approval",
   accepted: "Accepted",
   rejected: "Rejected",
   bank: "Bank",
   meliBank: "Meli Iran",
   sepahBank: "Sepah",
   toseBank: "Tose Saderat",
   sanatBank: "Sanat va Madan",
   keshavarziBank: "Keshavarzi",
   maskanBank: "Maskan",
   postBank: "Post Bank Iran",
   toseTavonBank: "Tose Taavon",
   eghtesadBank: "Eghtesad Novin",
   parsianbank: "Parsian",
   pasargardBank: "Pasargad",
   karafarinBank: "Karafarin",
   samanbank: "Saman",
   sinaBank: "Sina",
   sarmayeBank: "Sarmaye",
   tatBank: "Tat",
   shahrBank: "Shahr",
   deyBank: "Dey",
   saderatBank: "Saderat",
   melatBank: "Melat",
   tejarat: "Tejarat",
   refahbank: "Refah",
   ansarBank: "Ansar",
   mehrEghtesadBank: "Mehr Eghtesad",
   zeroLevelUser: "Zero level user",
   oneLevelUser: "One level user",
   twoLevelUser: "Two level user",
   authentication: "Authentication",
   bankInfo: "Bank information",
   personalImage: "Personal photo",
   adminRejection: "The information entered in this section was not approved by the admin! Please resubmit after editing",
   adminAcceptation: "The information in this section has been approved by the admin",
   adminPending: "The information in this section has been sent to the admin and is awaiting for approval",
   cancelRequest: "cancel request",
   province: "province",
   city: "city",
   submitInformation: "submit information",
   submitInfoAsk: 'Click "Submit" if you are sure your information is correct',
   save: "save",
   saveAndSubmit: "save and submit",
   varifyEmailCode: "The verification code was sent to your email",
   send: "send",
   validationCode: "Validation code",
   code: "code",
   topic: "topic",
   sendDate: "sent date",
   changePassword: "Change the password",
   enterCurrentPassword: "Enter your current password",
   EnterNewPassword: "Enter your new password",
   repeatNewPassword: "Repeat to enter your current password",
   activeTwoFactorIdentification: "Activate two-step identification",
   ActivateGoogleAuth: "Activate google authenticator",
   EnableLoginSMS: "Enable login SMS",
   LoginLogoutInformation: "Login and logout information",
   browserId: "Browser ID",
   Ip: "Ip",
   LoginLogout: "Login and logout",
   ViewFinancialReports: "View financial reports",
   AmountInRials: "Amount in Rials",
   networkFee: "Network fee",
   maxBuy: "Maximum amount of your purchase",
   registerBuyorder: "Register a buy order",
   registerSellorder: "Register a sell order",
   WalletAddress: "Wallet address",
   ongoingOrders: "Ongoing orders",
   completedOrders: "Completed orders",
   SendMessageToBackup: "Send message to system backup",
   requestSubject: "Subject of request",
   message: "message",
   sendMessage: "send Message",
   EnterPassword: "Enter your password",
   forgotPassword: "I forgot my password.",
   completeNumbers: "Please complete the numbers as 123",
   LoginToSatrex: "Login to Satrex",
   RegisterAtSatrex: "Register at Satrex",
   "newUser?": "Are you a new user?",
   urlCheck: "Please check that you entered from this address",
   mobile: "mobile",
   RepeatPassword: "Re-enter your password",
   repeatPassword: "repeat the password",
   "alreadyRegistered?": "already registered ?",
   EnterAuthCode: "Enter Auth Code",
   codeSentToMobile: "Confirmation code sent to mobile number",
   ResendCodeUp: "Resend code up to",
   resend: "resend",
   continue: "continue",
   password: "Password",
   transactionValue: "The value of the transaction",
   BoardOfCompletedTransaction: "Board of completed transaction",
   theLowestPrice: "the lowest price",
   theHighestPrice: "the highest price",
   BuyAndSellOrders: "Buy and sell orders",
   Number: "Number",
   SendOrder: "Send order",
   openOrders: "Open orders",
   row: "Row",
   currencyName: "Currency name",
   requestedCurrency: "Requested currency",
   OrderType: "Order type",
   totalAmount: "Total Amount",
   TheAmountFilled: "The amount filled",
   todayOrders: "Today orders",
   cancelOrder: "Cancel order",
   "sureToCancelOrder?": "Are you sure you want to cancel the order?",
   marketsOf: "Markets of",
   update: "update",
   depositPageDescriptionLine1:
      "To increase the credit of your Rial wallet by using the bank cards of Shetab network members approved in your profile and through the online payment portal.",
   depositPageDescriptionLine2: "Pay attention to the following points when paying",
   depositPageDescriptionLine3:
      "After checking the page address of the banking portal and making sure that the address of the Shaparak system sites is correct, enter your bank card details and make a deposit.",
   depositPageDescriptionLine4: "On the portal page, make sure that the entered amount is correct.",
   depositPageDescriptionLine5:
      "In determining the deposit amount, pay attention to the fact that the minimum transaction amount in the Satrex market is one hundred thousand tomans.",
   depositWithdrawalCryptoPageLine1:
      "The address of your wallet can be seen in the box below. Use the displayed address to deposit digital currencies to this bag.",
   depositWithdrawalCryptoPageLine2: "If you want to withdraw your wallet balance, register your request here.",
   securityPage1:
      "By activating two-factor authentication to log in, in addition to your username and password, you will also need to enter a six-digit code. To generate this code, you use an app that will be installed on your phone. Enabling two-factor authentication increases the security of your account, and it is recommended that you enable it.",
   securityPageTableLine1:
      "In order to further protect your account, it is recommended that you pay attention to the login notification emails that are sent to you. You can also view the history of successful and unsuccessful logins to your account.",
   destinationWalletAddressNote:
      "Note: Make sure the address you enter is correct, the wrong address will lead to the loss of your withdrawal amount.",
   destinationWalletAddressWithTagNote:
      "Note: Make sure the address and tag you enter is correct, the wrong address or tag will lead to the loss of your withdrawal amount.",
   YourWithdrawableInventory: "Your withdrawable inventory",
   clipboardPermissionsError: "Unable to access the clipboard Please apply for permission",
   copied: "Copied",
   showAddress: "Show address",
   updateInventory: "Update inventory",
   operations: "Operations",
   reports: "reports",
   services: "Services",
   calculator: "calculator",
   introduceFriends: "introduce to friends",
   apiDocs: "API documentation",
   guide: "Guide",
   registerGuide: "Registration guide",
   FAQ: "FAQ",
   authGuide: "Authentication guide and user level",
   priceGuide: "Help prices and commissions",
   securityGuide: "Account Security Guide",
   satrex: "Satrex",
   aboutUs: "about us",
   contactus: "Contact us",
   terms: "Terms and conditions of use",
   satrexAddress: "Mashhad-Sayad Shirazi Boulevard-Stasenter commercial complex",
   onSocialMedia: "We are on social networks",
   satrexRights: "The material and intellectual rights of this site belong to Satrex and any copying of it will be prosecuted.",
   AboutSatrex: "About Satrex",
   headOfficeInfo: "Head office contact information",
   phone: "Phone",
   openingHours: "opening hours",
   openingHoursSatrex: "Saturday to Wednesday from 8:30 to 17",
   contactForm: "Contact form",
   firstNameAndLastName: "first name and last name",
   contactNumber: "Phone number",
   toman: "Toman",
   WaitingForSupportUserReply: "waiting for support user reply",
   AnsweredBySupportUser: "answered by support user",
   ClosedByEndUser: "closed by end user",
   ClosedBySupportUser: "closed by support user",
   ClosedBySystem: "closed by system",
   High: "High",
   Normal: "Normal",
   Low: "Low",
   Rejected: "Rejected",
   Pending: "Pending",
   AcceptedAndPaid: "Accepted and paid",
   CanceledByUser: "Canceled by user",
   bitCoin: "bitcoin",
   DogeCoin: "Dogecoin",
   One: "One",
   ChangesIn24Hours: "Changes in 24 hours",
   MarketRank: "Market rank",
   "DepositAmount(Toman)": "Deposit amount (Toman)",
   "YourDepositLimit(Toman)": "Your deposit limit (Toman)",
   RegisterYourRialBalanceWithdrawalRequest: "Register your Rial balance withdrawal request.",
   withdrawalPageDescriptionLine2:
      "If Saman Bank account is registered and used, it will be possible to transfer inside the bank and expedite the withdrawal request.",
   SaterexRialSettlementSchedule: "Saterex Rial Settlement Schedule",
   "Inventory(Toman)": "Inventory (Toman)",
   "WithdrawalAmount(Tomans)": "Withdrawal amount (Tomans)",
   TotalDailyWithdrawals: "Total daily withdrawals",
   TotalMonthlyWithdrawals: "Total monthly withdrawals",
   SelectOneOfYourApprovedBankAccountsToReceiveTheMoney: "Select one of your approved bank accounts to receive the money.",
   RequestHours: "Request hours",
   activities: "activities",
   All: "All",
   Daily: "Daily",
   Weekly: "Weekly",
   monthly: "monthly",
   "YouAreInTheAuthenticationPhase.": "You are in the authentication phase.",
   YourContactInformationHasBeenSentToTheAdminAndIsAwaitingReview:
      "Your contact information has been sent to the admin and is awaiting review",
   YourPhotosHaveNotBeenUploadedYet: "Your photos have not been uploaded yet",
   AccountStatus: "Account status",
   ApprovedLevel: "Approved Level",
   UserLevel: "UserLevel",
   "500thousandTomansPerDay": "500 thousand tomans per day",
   RialWithdrawalCeiling: "Rial withdrawal ceiling",
   RialDepositCeiling: "Rial deposit ceiling",
   "20millionTomansPerDay": "20 million tomans per day",
   RialAndCryptocurrencyHarvestCeiling: "Rial and cryptocurrency harvest ceiling",
   "100millionTomansPerDay": "100 million tomans per day",
   UpgradeYourUserInterfaceToCompleteTheAuthenticationProcessAndEnjoyItsBenefits:
      "Upgrade your user interface to complete the authentication process and enjoy its benefits. Help verify your identity",
   TheFirstStage: "The first stage",
   LastReplyDate: "Last reply date",
   FinancialReports: "Financial reports",
   orders: "orders",
   "UnitPrice(Tomans)": "Unit price (Tomans)",
   "TotalAmount(Tomans)": "Total amount (Tomans)",
   NoteToViewYourQuickOrderListYouCanReferToTheReportsSection: "Note: To view your quick order list, you can refer to the reports section",
   FastOrders: "Fast orders",
   FastDeals: "Fast deals",
   Priority: "Priority",
   RelevantDepartment: "Relevant Department",
   technicalSupport: "technical support",
   Financial: "Financial",
   Price: "Price",
   GettingStartedInSatrex: "Getting started in Satrex",
   helpPageDescriptionLine1:
      "After registering and confirming your account, you can top up your account in Rials or cryptocurrency and enjoy trading in the cryptocurrency market you want in a safe and fast way.",
   helpPageDescriptionLine2: "How to buy and sell digital currency in Satrex",
   helpPageDescriptionLine3:
      "In Satrex Digital Exchange you can exchange three methods of limited ordering, market ordering and fast ordering in different cryptocurrencies. Read more in this section for more information",
   helpPageDescriptionLine4: "Learn how to register for Satrex in this section",
   helpPageDescriptionLine5: "Learn how to authenticate in Satrex in this section.",
   helpPageDescriptionLine6: "Deposit Rials",
   helpPageDescriptionLine7: "Rial deposit steps and restrictions related to each user level are available in this section",
   helpPageDescriptionLine8: "Withdrawal of Rials",
   helpPageDescriptionLine9: "Rial withdrawal steps and restrictions related to each user level are available in this section",
   helpPageDescriptionLine10: "Deposit cryptocurrency",
   helpPageDescriptionLine11: "Read the tips for depositing cryptocurrency in your account in this section",
   helpPageDescriptionLine12: "Read the tips for withdrawing cryptocurrency in your account in this section.",
   helpPageDescriptionLine13: "Help user levels",
   helpPageDescriptionLine14:
      "Get different user levels, documents and how to authenticate, restrictions and features of each level from this section",
   helpPageDescriptionLine15: "Guide to sending personalized photos",
   helpPageDescriptionLine16: "Learn how to send a personal image in this section",
   helpPageDescriptionLine17: "Follow the steps below to get started with Satrex Digital Exchange.",
   helpPageDescriptionLine18: "Registration in Satrex system with mobile number registered in the user name (Registration video help)",
   helpPageDescriptionLine19: "Login and complete the authentication process (Video authentication guide)",
   helpPageDescriptionLine20: "Rial deposit or cryptocurrency (Video guide for Rial deposit and cryptocurrency)",
   helpPageDescriptionLine21: "Registration of purchase or sale order in Satrex system (video order registration help)",
   helpPageDescriptionLine22: "Withdrawal of Rials or cryptocurrency (Video guide of withdrawing Rials and cryptocurrency)",
   helpPageDescriptionLine23:
      "Dear Satrex users, after registering, completing and confirming your profile information, you can recharge your account and exchange in the desired cryptocurrency market.",
   helpPageDescriptionLine24:
      "In this type of ordering, you can register your desired passwords at the requested price in the market. Depending on the selected volume and price, market fluctuations and demand, the registered transaction will be done in part or in full. It should be noted that in this type of order, there is no guarantee for a complete transaction. Your limited order information can be seen in the open order table. If the transaction is complete, the details of the transaction will be available from the menu of reports / orders made. If all or part of the transaction is not done, you can release the blocked capital by canceling the order from the open orders menu.",
   helpPageDescriptionLine25:
      "In this type of ordering, you will trade your requested volume with the best prices registered in the mentioned cryptocurrency market. Since the volume you request will not necessarily be the same as the volume registered in the market, this type of transaction may be done in several stages and at different prices. If your requested volume is more than the total orders registered in the market, the remaining part of your open orders will be waiting for a deal. If you wish, you can cancel the rest of the order from the open orders menu to release the blocked capital.",
   helpPageDescriptionLine26:
      "In this type of order, you will trade the requested volume of Ramzars with the price set by the system. Fast trades will definitely take place, unless severe market fluctuations occur from the moment the price is displayed in the relevant field until the order is placed. In this case, your order system will be canceled, the blocked capital will be automatically released and the order cancellation SMS will be sent to you.",
   helpPageDescriptionLine27:
      "To register, first go to Satrex website https://www.satrex.ir, then click on the registration option in the upper left bar.",
   helpPageDescriptionLine28:
      "The registration form will be displayed for you. Please enter the required information including mobile number, password and repeat password carefully and click on the registration button. Note that the entered mobile number must be in the user name",
   helpPageDescriptionLine29: "In this step, enter the verification code sent to the mobile number and click the Continue button",
   helpPageDescriptionLine30: "As soon as you enter the correct code, you will be logged in to your user panel.",
   helpPageDescriptionLine31:
      "After registering in Satrex, in order to use the system facilities, the user authentication steps must be performed and approved by the system administrator. The required documents for authentication according to different levels of use are as follows:",
   helpPageDescriptionLine32:
      "From the Profile menu, select Edit Personal Information and complete the Identity Information Form. At this point you need a valid email address. After entering the email address, click on the send code option and complete the confirmation step of your email by entering the sent code.",
   helpPageDescriptionLine33:
      "In the next step from the profile menu, select Edit Bank Information Click on Add Bank Account. Enter the card number (to deposit Rials) and the Sheba number (to withdraw Rials) in the box, then click the save button. Note that the bank card must belong to the user and refrain from registering other people's bank cards",
   helpPageDescriptionLine34:
      "After confirming the user level information, 1 will be registered for you. For information on different user levels, refer to the user levels page.",
   helpPageDescriptionLine35:
      "Fill in the contact information form and send the national card image and personal image according to the sample from the personal information editing menu. After review and approval by the system administrator, your user level will be upgraded to level 2.",
   helpPageDescriptionLine36:
      "After confirming the authentication process for the transaction in Satrex, you can top up your account by depositing money or cryptocurrencies.",
   helpPageDescriptionLine37: "To deposit money, do the following:",
   helpPageDescriptionLine38: "1. Select the deposit option from the accounting menu.",
   helpPageDescriptionLine39:
      "2. Select one of the bank cards registered and approved in the system. Enter your desired amount in Tomans in the specified box. Then click on Transfer to Banking Portal.",
   helpPageDescriptionLine40:
      "3. Complete the banking portal information. Note that use a bank card that is registered and approved in the system.",
   helpPageDescriptionLine41: "To withdraw money, do the following:",
   helpPageDescriptionLine42: "1. Select the withdrawal option from the accounting menu.",
   helpPageDescriptionLine43:
      "2. Enter your desired withdrawal time and amount in Tomans and select the night number of one of the bank accounts registered and approved in the system. Then submit your request by sending an SMS code and entering it in the relevant box.",
   helpPageDescriptionLine44:
      "3. After confirmation by the relevant expert, the requested amount will be credited to your account and a confirmation SMS will be sent to you. You can follow the status of your request from the requests page.",
   helpPageDescriptionLine45:
      "You can easily transfer your passwords and make a transaction or withdrawal by creating a wallet address in Satrex.",
   helpPageDescriptionLine46: "1. From the Accounting menu, select Deposit and Withdraw Cryptocurrency",
   helpPageDescriptionLine47:
      "2. From the deposit section, select the desired passwords and click on the address display button. The address created in Satrex is unique to each user and is permanent.",
   helpPageDescriptionLine48: "3. Copy the wallet address generated in the box or scan the QR code.",
   helpPageDescriptionLine49: "4. Enter the copied address in the wallet of the money you want to deposit or give it to the sender.",
   helpPageDescriptionLine50: "5. After the deposit, wait for the confirmation of the blockchain network to charge your Satrex wallet.",
   helpPageDescriptionLine51: "6. After the deposit, wait for the confirmation of the blockchain network to charge your Satrex wallet.",
   helpPageDescriptionLine52: "1. First, enter the deposit and withdrawal page of cryptocurrency from the accounting menu",
   helpPageDescriptionLine53:
      "2. From the cryptocurrency withdrawal field, select the desired cryptocurrency, enter the withdrawal amount and the destination wallet address. Then, by clicking on the SMS code request button, enter the verification code sent to your mobile number and register your request.",
   helpPageDescriptionLine54:
      "3. Wait for the approval of the relevant expert. You can follow the status of your request from the requests page.",
   helpPageDescriptionLine55:
      "There are several different user levels in Satrex so that users can enjoy the benefits of each level according to their needs.",
   helpPageDescriptionLine56:
      "Users who complete the personal information and banking information form after registering with Satrex and the accuracy of their information is confirmed by the system will be at level 1 user. It should be noted that at this stage, it is necessary to enter the bank card number and Sheba number in the user's name.",
   helpPageDescriptionLine57:
      "Users who, in addition to personal and banking information, have completed their contact information form and uploaded the national card image and personal image according to the sample in the system and the accuracy of their information has been confirmed by the system will be at level 2 users. The advantages of this level are the increase of the deposit and withdrawals ceiling as follows:",
   helpPageDescriptionLine58:
      "cryptocurrency deposit will be unlimited and Rial deposit will be in accordance with the Central Bank restrictions (currently 50 million from each card and the total of bank cards up to 100 million per day). The maximum withdrawal request for level 2 users is equivalent to 500 million Tomans in the last 24 hours (300 million Tomans in Rials and 200 million Tomans in cryptocurrency). The maximum withdrawal request in the last 30 days will be equal to 3 billion tomans.",
   helpPageDescriptionLine59:
      "The authentication process for level 2 users will be completed by sending a personal image according to the sample. For this purpose, hold the national card, bank card and commitment form according to the sample and prepare a clear image of yourself.",
   helpPageDescriptionLine60: "In preparing a personal photo, pay attention to the following points:",
   helpPageDescriptionLine61:
      "1. Hold the national card in such a way that its information is visible. If for any reason you do not have access to a national card, use a new identity card or valid passport.",
   helpPageDescriptionLine62:
      "2. Hold one of the bank cards you have registered in the system or stick it on the pledge paper. You can cover the expiration date and CCV2 information, but do not corrupt the card number and name of the cardholder.",
   helpPageDescriptionLine63: "3. Write and sign the text of the following letter of commitment on paper.",
   helpPageDescriptionLine64:
      "4. Note that when preparing the image, handwritten text and national card information should not be recorded in reverse and in a mirror.",
   helpPageDescriptionLine65:
      "I (name and surname) to the national code 00000000000000 I read the rules and conditions of use of Satrex and I undertake not to give my account to others. In case of any violation, I will be responsible for it financially, judicially and legally.",
   helpPageDescriptionLine66: "Name, date and signature",
   helpPageDescriptionLine67: "Was this article helpful to you?",
   "1.LimitedOrdering": "1. Limited ordering",
   "2.MarketOrdering": "2. Market ordering",
   FastOrdering: "Fast ordering",
   UserLevel1: "User level 1",
   totalTradable: "Negotiable inventory",
   favoritMarkets: "Favorit Markets",
   noVarifiedBankFound: "No verified bank account found!",
   "100millionCryptocurrencyPerDay": "100 million Tomans per day and deposit unlimited Cryptocurrency",
   "300MillionTomansPerday": "300 million tomans per day",
   "500MillionTomansPerday": "500 million tomans per day",
   MaximumLevelTwoApplicationsIn30days: "Ceiling set of level two applications in 30 days",
   "3BillionTomans": "3 billion tomans",
   HelpVerifyYourIdentity: "help verify your identity",
   ApprovedBySystem: "Approved by system",
   youAreInAuthPhase: "You are in the authentication phase",
   profileLine1:
      "Dear Satrex user, if you want to start financial activity on Sutrex site, start your authentication process after reading the description.",
   profileLine2:
      "Basic authentication is done by confirming the mobile phone number, email, sending identity documents (national card), entering the address of residence, landline and confirming them, and entering account and bank card information. After confirming the information, you will be able to deposit / coin, trade and withdraw / coin.",
   profileLine3:
      "It should be noted that according to the banking laws of the country, after registering each new bank card, they will not be able to register a withdrawal request from that card for one working day. This delay is only to confirm the authenticity of the bank card, and during this period, users can trade any amount with their deposit in the system and convert it into other digital currencies, and only the restriction on withdrawing the deposit for one working day from Is the Satrex system.",
   PreviousStep: "Previous step",
   NextStep: "Next step",
   FirstLevel: "First level",
   SecondLevel: "Second level",
   ReviewRequest: "Review Request",
   details: "details",
   UserDeposit: "User deposit",
   rank: "Rank",
   LackAccessSection: "Lack of access to this section",
   suspendText: "Unfortunately you do not have access to this section, please increase your user level",
   marketAddToFav: "Market has been added to your favorites list",
   failedInMission: "The operation failed. Please try again",
   marketRemovedFromFav: "Market has been removed from your favorites",
   codeWasSent: "The code was sent to your mobile phone",
   selectCryptoRequired: "Selecting a Cryptocurrency is required",
   "Withdrawal value must be greater than zero": "Withdrawal value must be greater than zero",
   "WithdrawalMustBe>0": "Withdrawal value must be greater than zero",
   EnterDestinationWalletAddress: "Enter the destination wallet address",
   toFactorCodeRequired: "A two-factor identification code is required",
   WithdrawalRequestRegisteredSuccessfully: "Withdrawal request registered successfully",
   TransferToPort: "Transfer to port",
   YouRedirectedGateway: "You are being redirected to the gateway",
   SaveAndSubmitForAdminApproval: "Save and submit for admin approval",
   delete: "Delete",
   MessageDetails: "Message details",
   ReplySentSuccessfully: "Reply sent successfully",
   MessageText: "Message text",
   answerText: "Answer text",
   persianRequired: "This field cannot be anything other than Persian",
   "e-mail": "E-mail",
   discussionTopic: "discussion topic",
   "valueMustBe>0": "The value must be greater than zero",
   "priceMustBe>0": "The price must be greater than zero",
   LackOfSufficientInventory: "Lack of sufficient inventory",
   orderSuccessfullyRegistered: "Order successfully registered",
   maxSell: "Maximum amount of your sell",
   "HowDoesSatrexWork?(HowDoesSatrexWork?)": "How does Satrex work? (How does Satrex work?)",
   DescriptionsToBeCompletedByPeopleFamiliarWithTheSystem: "Descriptions to be completed by people familiar with the system",
   "WhyIsSatrexTrusted?": "Why is Satrex trusted?",
   faqPageDescriptionLine1:
      "Due to the development and expansion of the digital currency investment market, a group of the best programming specialists and analysts to provide a safe and secure environment with the highest level of security in the world for trading digital currency capital market under the laws of the Islamic Republic of Iran. .",
   faqPageDescriptionLine2: "How to start trading on Satrex?",
   faqPageDescriptionLine3:
      "After registering and entering the site, enter the profile section and complete your personal information and bank information there. In the following, you must verify your identity. After completing the authentication and verification steps by the support team, you will be able to trade in the cryptocurrency market.",
   faqPageDescriptionLine4: "What documents are required for authentication and what should be done?",
   faqPageDescriptionLine5:
      "In order to authenticate, it is necessary to upload a picture of your national work as well as a selfie of yourself next to the national card in the profile section. You also need to fill in the personal information as well as the bank information in your name in the profile section accurately.",
   faqPageDescriptionLine6: "How long does it take to authenticate information?",
   faqPageDescriptionLine7: "The Satrex team will verify your identity information within 24 hours.",
   faqPageDescriptionLine8: "How do I make money or digital currency into my Satrex account?",
   faqPageDescriptionLine9:
      "In order to deposit Rials, you can make a deposit operation in the accounting section of the deposit section with one of the cards registered in your account.",
   faqPageDescriptionLine10:
      "Also, in order to deposit the currency code, you can proceed to the accounting section of the deposit section, by creating the specific wallet address of the currency code you want to transfer the currency to the Satrex exchange.",
   faqPageDescriptionLine11: "What are the sales and withdrawal fees at Satrex?",
   faqPageDescriptionLine12: "What is the reason for authentication and why should we be authenticated?",
   faqPageDescriptionLine13:
      "According to the latest rules for digital currency transactions, in order to prevent and fight against money laundering, every person is required to first verify his / her identity so that in case of violation, the necessary follow-ups can be done.",
   faqPageDescriptionLine14: "How to buy and sell digital currency in Satrex?",
   faqPageDescriptionLine15:
      "By registering on the site and performing authentication and verification operations by the site, a person can start her transaction on the Satrex site by depositing money or depositing digital currency in her wallet.",
   faqPageDescriptionLine16: "How long does it take to settle transactions?",
   faqPageDescriptionLine17: "What can be done to track transactions that have encountered problems?",
   faqPageDescriptionLine18:
      "Share your problem with support experts so that your problem can be followed up and resolved at the earliest opportunity.",
   faqPageDescriptionLine19: "What are the working hours of Satrex support?",
   faqPageDescriptionLine20:
      "If you do not find the answer to your question, you can contact our supporters through one of the mentioned ways.",
   levelguidePageDescriptionLine1:
      "Satrex defines different user levels for its users based on the authentication steps. At Satrex Digital Exchange, you will be able to trade with systematically authenticated users in the safest and safest environment with the least possible risk.",
   levelguidePageDescriptionLine2:
      "In the Satrex authentication system, the identity information, telephone number, bank account information and location information of the users will be automatically checked and approved by the relevant institutions. The advantages of this authentication method include the following:",
   levelguidePageDescriptionLine3: "Creating a secure trading environment through the presence of authenticated users.",
   levelguidePageDescriptionLine4: "Simple and fast authentication process",
   levelguidePageDescriptionLine5: "Start a comfortable and easy activity for low level users",
   levelguidePageDescriptionLine6:
      "Providing more professional services and the benefits of increasing the level of deposits and withdrawals for users at high levels",
   levelguidePageDescriptionLine7:
      "By confirming the mobile number with the user name, email, national code and bank account information, you will be at this user level. It is possible to deposit Ramzarz indefinitely, as well as the possibility of exchanging Ramzarz to Ramzarz and Ramzarz to Rials in the markets indefinitely for users of this level. Rial deposit ceiling for users of this level is 500 thousand Tomans in the last 24 hours. Ramzarz harvest ceiling in the last 24 hours is equal to 100 million Tomans and Rial harvest ceiling is equal to 20 million Tomans. The total amount of withdrawal requests for users of this level in the last 30 days is 1.5 billion Tomans",
   levelguidePageDescriptionLine8:
      "To upgrade to dual-user level, contact information, national card image and selfie photo according to the sample must be approved in the system. The possibility of depositing Ramzarz indefinitely and the possibility of depositing Rials through the accelerator portal according to the Central Bank restrictions from each bank card daily up to 50 million Tomans and the total bank cards up to 100 million Tomans per day. It will also be possible to exchange in Ramzarz to Ramzarz and Ramzarz to Rials markets indefinitely for users of this level. Ramzarz and Rial withdrawal ceiling in total in the last 24 hours is equivalent to 500 million Tomans (300 million Tomans Rial withdrawal ceiling and 200 million Tomans Ramzarz withdrawal ceiling). The total amount of requests for users of this level in the last 30 days is 3 billion Tomans.",
   levelguidePageDescriptionLine9:
      "To create a company account in Satrex, the letter of commitment form must be completed by the CEO or the company's representative and printed on the company's letterhead. It should be mentioned that in the first stage, the CEO or the representative of the company must activate his real user account, then the necessary documents, including the registration announcement of the latest changes of the company; The economic code certificate and the commitment form should be sent in the form of a zip file to the email address info@satrex.ir with the subject of 'company account'. The limit of the company account is the same as level two and it is possible to upgrade the level.",
   feeguidePageDescriptionLine1:
      "With the aim of creating a transparent, safe and accurate environment for its users, Satrex informs its users about the costs and fees of all financial operations, including deposits, withdrawals and transactions.",
   feeguidePageDescriptionLine2: "The transaction fee in Satrex is calculated based on the following terms and conditions:",
   feeguidePageDescriptionLine3: "Fees are deducted from both parties to the transaction, the buyer and the seller",
   feeguidePageDescriptionLine4:
      "In Satrex, the transaction fee is a percentage of the total transaction amount, which is calculated based on the volume of user transactions in the last 30 days, according to the table below. It should be noted that the commission for each side of the transaction will be deducted based on the assets received. For example, the digital currency buyer's commission will be deducted as a percentage of the digital currency received and the digital currency seller's commission will be deducted from the amount received in Tomans.",
   feeguidePageDescriptionLine5: "By submitting a transaction request to Satrex, the amount of the transaction fee will be displayed.",
   feeguidePageDescriptionLine6: "Satrex Trading Fee Schedule, which decreases in steps as the monthly trading volume increases:",
   feeguidePageDescriptionLine7:
      "Transactions in currency pairs markets (markets with Tetra destinations such as Bitcoin / Tetra), regardless of volume, are calculated separately with a fee of 0.13% for maker and 0.15% for taker.",
   feeguidePageDescriptionLine8: "Digital Currency Withdrawal Fee",
   feeguidePageDescriptionLine9:
      "The fee for withdrawing digital currencies depends on the type of currency. This cost is actually deducted due to network transaction fees and Satrex has no role in determining it. Currently these costs are as follows",
   feeguidePageDescriptionLine10: "Deposit and withdrawal fee in Rials",
   feeguidePageDescriptionLine11: "Rial deposit fee is zero.",
   feeguidePageDescriptionLine12:
      "Rial withdrawal fee includes bank charges and Satrex will not have a share of it. According to banking laws, the Rial withdrawal fee will be according to the table below",
   feeguidePageDescriptionLine13:
      "According to the stable settlement cycles in the country, according to the announcement of the Central Bank, the settlement times of Saturn Rials are as follows:",
   feeguidePageDescriptionLine14:
      "Rial withdrawal requests are processed only on working days and on holidays it is not possible to settle except in special cases due to the decommissioning of Satna and Paya networks.",
   feeguidePageDescriptionLine15:
      "Registered applications will be processed by the Central Bank by 12:30, in the 13:45 cycle, and usually with a delay of half to one hour will be deposited in the account of esteemed users.",
   feeguidePageDescriptionLine16:
      "Registered applications will be deposited in the account of esteemed users from 12:30 to 22:00, on the next working day from 4 to 7 in the morning.",
   feeguidePageDescriptionLine17:
      "Registered applications will be processed by the Central Bank after 10:00 AM to 9:00 AM, and will usually be credited to the account of esteemed users with a delay of half to one hour.",
   aboutusPageDescriptionLine1:
      "Due to the expansion and development of the world of digital currency trading and the increasing interest of traders in digital currency markets, as well as the comprehensive boycott of respected Iranian users by international exchanges, Satrex Group, which consists of the best and most experienced programmers, security experts Network and analysts have created a safe and reliable environment to facilitate your deal, dear compatriots, so that the honorable people of Iran can start trading in the market with comfort and keeping their capital in a safe, high-speed and easy-to-use interface. Capitalize digital currencies.",
   aboutusPageDescriptionLine2: "The purpose of Satrex",
   aboutusPageDescriptionLine3:
      "Creating the highest level of security in the world to protect the assets of respected users and also creating a very easy user interface for all active people in this market with high speed of transactions.",
   aboutusPageDescriptionLine4: "The advantages of Satrex can be:",
   aboutusPageDescriptionLine5: "Asset security of esteemed users",
   aboutusPageDescriptionLine6: "Trading the best and most reputable digital currencies",
   aboutusPageDescriptionLine7: "Convert different currencies to each other",
   aboutusPageDescriptionLine8: "Protecting users assets from international sanctions",
   aboutusPageDescriptionLine9: "Easy and fast access to the trading market through the website and application",
   aboutusPageDescriptionLine10: "24-hour support by the best and most experienced people",
   "Cited.": "Cited.",
   policiesPageDescriptionLine1: "Terms and Conditions of Use of Satrex Services",
   policiesPageDescriptionLine2:
      "Satrex Company is a platform for exchanging digital assets with the countrys currency (Tomans). This company is subject to the laws of the Islamic Republic of Iran and no currency exchange, whether buying or selling dollars or other paper currencies, takes place in this context.",
   policiesPageDescriptionLine3: "Commitment to operate in the context of Satrex digital asset exchange",
   policiesPageDescriptionLine4:
      "Dear users, before registering on the site, you are obliged to read all the clauses of the undertaking and if you accept it, to register on the site.",
   policiesPageDescriptionLine5:
      "All activities of the site users accept that their activities on the Satrex site are within the framework of the laws of the Islamic Republic and their activities will not be outside the laws of the Islamic Republic.",
   policiesPageDescriptionLine6:
      "The minimum age for registration, authentication and activity in Satrex is 18 years, and authentication operations will not be performed for minors.",
   policiesPageDescriptionLine7:
      "To use Satrex services, the user must first authenticate. Users are required to upload their correct information for authentication. In the event of any discrepancy in the information uploaded for authentication, it is obvious that the offender is responsible and Satrex will have the right to stop providing services to the user and refer cases of violations to legal authorities. Satrex also considers itself obligated to protect users personal information from others.",
   policiesPageDescriptionLine8:
      "Dear Satrex users promise that they have enough information about the risks in the digital currency investment market and are aware of this and invest.",
   policiesPageDescriptionLine9:
      "Satrex has no responsibility for the transactions and profits and losses of its esteemed users in the investment market.",
   policiesPageDescriptionLine10:
      "Satrex is not responsible for users negligence in maintaining security information, including passwords, and will be the sole responsibility of the user. (It is recommended to use two-factor identification code to maintain more security.)",
   policiesPageDescriptionLine11:
      "Satrex gives its users the assurance to protect their assets with the highest security and to compensate the users in case of any security problem.",
   policiesPageDescriptionLine12:
      "If you want to transfer digital currencies, the user is responsible for providing the correct wallet address, and in case of any problem in transferring digital currency, such as entering the destination wallet address incorrectly, blocking users assets in the destination wallet, etc. Is the responsibility of the user and Satrex is not responsible for this.",
   policiesPageDescriptionLine13:
      "Satrex has no responsibility for depositing tokens or coins on the wrong bed or coins that are not supported in Satrex, and due to the cold storage mechanism, it is not possible to extract these tokens using third volt. Therefore, any wrong deposit is the responsibility of the user and the user will not have the right to complain about Satrex. • The request to withdraw Rials from the site will be reviewed and registered as soon as possible, and finally it will be credited to the users account within 72 hours.",
   policiesPageDescriptionLine14:
      "The user acknowledges that, except in cases committed by Satrex, she has no right to make any claims, claims or complaints against the Satrex site, managers, employees and persons associated with this site.",
   policiesPageDescriptionLine15:
      "If Satrex mistakenly or unjustifiably transfers funds or cryptocurrencies to the users account or makes any errors in its calculations, it is authorized and independent at any time, directly and independently, without carrying out any administrative or judicial formalities and obtaining written permission. The obligor (account holder) should take action to eliminate the mistake and withdraw from his accounts, and Satrex will be valid in the event of a mistake or payment without fee and the need to withdraw from the account, and the user has the right to any objections and claims about how Satrex works from any The direction drops.",
   policiesPageDescriptionLine16:
      "In the event of any problems or ambiguities in any of the transactions, Satrex has the right to directly cancel the transaction and return the assets of either party to their own account. Obviously, if the bug is from Satrex, it will be obliged to compensate the damage, otherwise the user will be deprived of any right to object and claim about how Satrex works in any way.",
   policiesPageDescriptionLine17:
      "Satrex can change the relevant rules and regulations at any time depending on the applicable terms and conditions, and the user is always obliged to be fully aware of the latest changes in the rules.",
   policiesPageDescriptionLine18: "Authentication process",
   policiesPageDescriptionLine19:
      "In order to comply with anti-money laundering laws, all users are required to authenticate by Sutrex to prevent violations. Authentication is done in ...... step by presenting the relevant documents.",
   welcome: "welcome",
   cryptoNotFoundInWallet: "Currency not found in your wallets!",
   note1InWalletPage:
      "Note: Your Cryptocurrency balance in Tomans is calculated based on the last transaction made for each Cryptocurrency in the forum.",
   note: "Note :",
   "HowToRegisterInSatrex?": "How to register in Satrex?",
   answerOfregistration:
      "After entering the site, enter the site registration section and enter the mobile number to do the initial registration.",
   transferMoneyDate:
      "According to banking rules and money transfer restrictions (Rials), the maximum money transfer to your bank account is 72 hours.",
   supportTeamWorkTime: "Support experts are ready to provide service to you, dear users, 24 hours a day.",
   HelpSatrexUserLevels: "Help Satrex User Levels",
   UserLevelOne: "User level one",
   UserLevelTwo: "User level two",
   CompanyAccount: "Company account",
   FeesAndServiceFees: "Fees and service fees",
   TradingFee: "Trading fee",
   "Trading volume of the last 30 days": "Trading volume of the last 30 days",
   "Less than 10 million tomans": "Less than 10 million tomans",
   "More than 100 million tomans": "More than 100 million tomans",
   "Between 50 and 100 million Tomans": "Between 50 and 100 million Tomans",
   "Withdrawal fee": "Withdrawal fee",
   "Less than 400 thousand tomans": "Less than 400 thousand tomans",
   "One percent of withdrawal": "One percent of withdrawal",
   "400 thousand tomans to 50 million tomans": "400 thousand tomans to 50 million tomans",
   "More than 50 million tomans": "More than 50 million tomans",
   "4000 Tomans for every 50 million Tomans": "4000 Tomans for every 50 million Tomans",
   "Total order amount": "Total order amount",
   "Average price done": "Average price done",
   "the last situation": "the last situation",
   "Matching details of your order with ID": "Matching details of your order with ID",
   "Please arrange the phrase opposite": "Please arrange the phrase opposite",
   firstLevel: "First Level",
   secondLevel: "Second Level",
   TotalAssets: "Total assets",
   mashhad: "mashhad",
   ghavamin: "Ghavamin",
   toseeIns: "Tose`e Financial and Credit Institution",
   bankmarkazi: "central",
   hekmat: "Hekmat",
   gardeshgari: "Gardeshgari",
   ToseSaderat: "Tose`e saderat",
   iranzamin: "Iran Zamin",
   mehriranian: "Gharz al-Hasna Mehr Iran",
   EN: "English",
   FA: "Persian",
   policies: "Terms and conditions of use",
   "fast-dealing": "Fast Dealing",
   invitePageLine1: "Invite your friends to Sutrex by presenting your own code or link and earn money from their transaction fees.",
   invitePageLine2: "Invite your friends to Satrex with the following link.",
   invitePageLine3: "User statistics introduced by you",
   IConfirm: "ّI Confirm",
   firstLinePolicy:
      "Today, with the expansion of the flow of information and the daily use of the Internet and communication devices, the privacy of users has become one of the most serious concerns. The concern of many customers is the preservation of personal information, as well as mobile number, first and last name, email and bank account numbers that they give us for registration, login, authentication and bank deposits. It must be said that we also understand the importance of this issue and users' privacy is very important to us. Below you will find the privacy and confidentiality policy of user information.",
   secondLinePolicy:
      "In order to register in the Satrex app and website, it is necessary to provide us with information such as your first and last name, national code, mobile phone and email. Receiving your personal details and bank account numbers is also only for registering on the site, authentication, necessary arrangements for depositing funds to your accounts, sending important notifications and informing you of events and special discounts, using various company services. Satrex is",
   thirdLinePolicy:
      "Despite the fact that security is defined in a relative manner, in the design of the application and website of Satrex company, it has been tried to consider the highest levels of hardware and software security. When registering and receiving your personal information, requesting a one-time code, downloading company software, registering various requests, sending questions, etc., all requests and services will be stored on our secure servers and protected by strong firewalls. Your personal information will also be transferred to our website encrypted in a secure HTTPS platform and kept for future interactions.\n" +
      "In addition, we do not receive any private banking information (including password, CVV2, etc.) from you. The bank information received from you only includes your bank card number and bank account number, and they will be used only when the funds are deposited into your account.",
   forthLinePolicy:
      "Both legally and ethically, we are obliged to protect your information and files. Your information is also stored in coded form only for future interactions so that you can easily enter the system and register your order in future orders and requests. We also hate spam and will not send promotional emails or messages to our users.",
   fifthLinePolicy:
      "Support and after-sales service colleagues have access to some of your information, which is used to provide better support and quality services. All your personal information will be kept with us and will not be shared with other people or organizations. It is obvious that legal authorities are able to access this information by submitting a court order. In general, we are obliged to protect and protect your information.",
   enoughLevelText:
      "Dear user, thanks for your trust in Satrex, we inform you that authentication is not necessary during all the steps and you can now benefit from all Satrex services by having level one authentication.\n" +
      "During all stages of authentication and reaching higher levels, it is required only if you need more withdrawal ceilings.",
};

export default props => dic[props];
