import React, { useState } from "react";
import Lottie from "react-lottie";
import { Button } from "@mui/material";
import SatrexDialog from "./SatrexDialog";
import { satrexGreen } from "../styles/colors";
import downloadApp from "../images/lottie/downloadApp.json";
import Trans from "./Trans";

const installOptions = {
   loop: true,
   autoplay: true,
   style: { width: "50%" },
   animationData: downloadApp,
};

const ShowInstallPopUp: React.FC = () => {
   const [showAlert1, setShowAlert1] = useState<boolean>(true);

   return (
      <>
         <SatrexDialog
            open={showAlert1}
            handleClose={() => setShowAlert1(false)}
            otherProps={{
               className: "installPwaAlert",
            }}
            content={
               <>
                  <Lottie options={installOptions} height={250} />
                  <h6 style={{ textAlign: "center", lineHeight: 2 }} dir="ltr">
                     <Trans>Do you want to install Satrex on your phone?</Trans>
                  </h6>
                  <div
                     style={{
                        width: "100%",
                        marginTop: 10,
                        display: "flex",
                        justifyContent: "center",
                     }}
                  >
                     <Button style={{ color: "gray" }} onClick={() => setShowAlert1(false)}>
                        <Trans>Later</Trans>
                     </Button>
                     &nbsp;&nbsp;&nbsp;&nbsp;
                     <Button style={{ backgroundColor: satrexGreen }} variant="contained" className="installPwa">
                        <Trans>install</Trans>
                     </Button>
                  </div>
               </>
            }
         />
      </>
   );
};

export default ShowInstallPopUp;
