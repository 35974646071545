export default dateToConvert => {
   let JalaliDate = {
      g_days_in_month: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      j_days_in_month: [31, 31, 31, 31, 31, 31, 30, 30, 30, 30, 30, 29],
   };

   JalaliDate.jalaliToGregorian = function (j_y, j_m, j_d) {
      j_y = parseInt(j_y);
      j_m = parseInt(j_m);
      j_d = parseInt(j_d);
      var jy = j_y - 979;
      var jm = j_m - 1;
      var jd = j_d - 1;

      var j_day_no = 365 * jy + parseInt(jy / 33) * 8 + parseInt(((jy % 33) + 3) / 4);
      for (var i = 0; i < jm; ++i) j_day_no += JalaliDate.j_days_in_month[i];

      j_day_no += jd;

      var g_day_no = j_day_no + 79;

      var gy = 1600 + 400 * parseInt(g_day_no / 146097); /* 146097 = 365*400 + 400/4 - 400/100 + 400/400 */
      g_day_no = g_day_no % 146097;

      var leap = true;
      if (g_day_no >= 36525) {
         /* 36525 = 365*100 + 100/4 */
         g_day_no--;
         gy += 100 * parseInt(g_day_no / 36524); /* 36524 = 365*100 + 100/4 - 100/100 */
         g_day_no = g_day_no % 36524;

         if (g_day_no >= 365) g_day_no++;
         else leap = false;
      }

      gy += 4 * parseInt(g_day_no / 1461); /* 1461 = 365*4 + 4/4 */
      g_day_no %= 1461;

      if (g_day_no >= 366) {
         leap = false;

         g_day_no--;
         gy += parseInt(g_day_no / 365);
         g_day_no = g_day_no % 365;
      }

      for (i = 0; g_day_no >= JalaliDate.g_days_in_month[i] + (i == 1 && leap); i++)
         g_day_no -= JalaliDate.g_days_in_month[i] + (i == 1 && leap);
      var gm = i + 1;
      var gd = g_day_no + 1;

      gm = gm < 10 ? "0" + gm : gm;
      gd = gd < 10 ? "0" + gd : gd;

      return [gy, gm, gd];
   };
   let dateSplitted = dateToConvert.split("-"),
      jD = JalaliDate.jalaliToGregorian(dateSplitted[0], dateSplitted[1], dateSplitted[2]);
   return jD[0] + "-" + jD[1] + "-" + jD[2];
};
