import React from "react";

export default [
   {
      selector: '[data-tut="buyAndSellSection"]',
      content: () => (
         <div>
            <p>لیست سفارشات خرید و فروش را در این بخش مشاهده کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="chooseOrderType"]',
      content: () => (
         <div>
            <p>نوع سفارش (خرید/فروش) را در این بخش میتوانید مشخص کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="chooseOrderTypeLimit"]',
      content: () => (
         <div>
            <p>نوع سفارش (Limit/Market) را در این بخش میتوانید مشخص کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="orderDetailInfo"]',
      content: () => (
         <div>
            <p>جزئیات سفارش را میتوانید در این بخش مشاهده کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="todayOrderList"]',
      content: () => (
         <div>
            <p>لیست تمام سفارش های امروز را میتوانید در این بخش مشاهده کنید</p>
         </div>
      ),
   },
];
