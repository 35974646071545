import React from "react";
import sliderTourConfig from "../../components/slider/tourConfig";
import InnerTabTourConfig from "../../components/InnerTabTourConfig";

export default [
   ...sliderTourConfig,
   ...InnerTabTourConfig,
   {
      selector: '[data-tut="chooseCryptoSection"]',
      content: () => (
         <div>
            <p>برای ثبت سفارش ابتدا باید کوین مورد نظر را از این قسمت انتخاب کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="setPriceSection"]',
      content: () => (
         <div>
            <p>مقدار مورد نظر برای ثبت سفارش در اینجا وارد کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="exchangePriceSection"]',
      content: () => (
         <div>
            <p>قیمت تعیین شده توسط صرافی در این مکان قرار میگیرد</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="totolPriceSection"]',
      content: () => (
         <div>
            <p>مجموع قیمت در ایجا قرار میگیرد</p>
            <p>همچنین با وارد کردن مجموع قیمت مورد نظر در این بخش میتوانید فیلد مقدار را تعیین کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="dealingDetailSection"]',
      content: () => (
         <div>
            <p>جزئیات معامله را میتوانید در این جا مشاهده کنید</p>
         </div>
      ),
   },
];
