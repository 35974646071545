import React, { useEffect, useState } from "react";
import { Device } from "@capacitor/device";
import { supportedLanguages, transFn } from "./components/Trans";
import { setLanguage } from "./redux/actions/LayoutActions";
import { useDispatch } from "react-redux";
import AskLanguage from "./components/AskLanguage";
import { StatusBar } from "@capacitor/status-bar";
import { App as CapApp } from "@capacitor/app";
import isPhone from "./utils/helpers/isPhone";
import { useIonToast } from "@ionic/react";
import { useHistory } from "react-router";
import PushNotificationsContainer from "./components/PushNotificationsContainer";
import { Toast } from "@capacitor/toast";
import { removeAuth } from "./utils/ApiConfig";
import history from "./history";

const PhonePlugins: React.FC = () => {
   const dispatch = useDispatch();
   const [showLangPopUp, setShowLangPopUp] = useState(false);
   const isAppInstalled = localStorage.getItem("appInstalled");
   const [present] = useIonToast();
   const history = useHistory();

   const handleBackBtnClickListener = () => {
      if (isPhone()) {
         let touchedBackBtn = false;
         CapApp.addListener("backButton", async ({ canGoBack }) => {
            if (!canGoBack) {
               if (!touchedBackBtn) {
                  await Toast.show({
                     text: transFn("Press the return key to exit again"),
                  });
                  touchedBackBtn = true;
                  setTimeout(() => {
                     touchedBackBtn = false;
                  }, 3000);
               } else {
                  removeAuth();
                  history.push("/login");
                  CapApp.exitApp();
               }
            } else {
               history.goBack();
            }
         });
      }
   };

   const handleLanguageSelection = async () => {
      try {
         if (!localStorage.getItem("language")) {
            const lang = await Device.getLanguageCode();
            const appliedLanguage = supportedLanguages.find((lan: string) => lan === lang?.value?.toUpperCase()) ?? "EN";
            if (appliedLanguage !== "FA") setShowLangPopUp(true);
            dispatch(setLanguage(appliedLanguage));
         }
      } catch (e) {}
   };

   const handleShowStatusBar = () => {
      window.addEventListener("statusTap", async () => {
         await StatusBar.show();
         setTimeout(async () => {
            await StatusBar.hide();
         }, 2000);
      });
   };

   useEffect(() => {
      handleLanguageSelection();
      handleBackBtnClickListener();
      handleShowStatusBar();
   }, []);

   return (
      <>
         {showLangPopUp && <AskLanguage />}
         <PushNotificationsContainer />
      </>
   );
};

export default PhonePlugins;
