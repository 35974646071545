import React from "react";

export default [
   {
      selector: '[data-tut="chooseCryptoSection"]',
      content: () => (
         <div>
            <p>برای واریز رمز ارز ابتدا کوین مورد نظر را از این قسمت انتخاب کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="chooseNetwork"]',
      content: () => (
         <div>
            <p>بعد از انتخاب کوین مورد نظر میتوانید شبکه مربوطه را در این قسمت انتخاب کنید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="walletBalanceSection"]',
      content: () => (
         <div>
            <p>موجودی کیف پول شما در این بخش نمایش داده میشود</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="updateInventorySection"]',
      content: () => (
         <div>
            <p>برای نمایش آخرین موجودی شما روی بلاک چین این دکمه را بفشارید </p>
         </div>
      ),
   },
   {
      selector: '[data-tut="showAddressSection"]',
      content: () => (
         <div>
            <p>برای نمایش آدرس بلاک چین کوین انتخابی این دکمه را بفشارید</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="blockchainAddressSection"]',
      content: () => (
         <div>
            <p>در این بخش آدرس بلاک چین شما قرار میگیرد</p>
         </div>
      ),
   },
   {
      selector: '[data-tut="qrCodeSection"]',
      content: () => (
         <div>
            <p>QRCode مربوط به آدرس بلاک چین در این قسمت نمایش داده میشود</p>
         </div>
      ),
   },
];
